import React from 'react';

import PropTypes from 'prop-types';

import { UtilityFlagIcon as PriorityIcon } from '@peakon/bedrock/icons/system';

import Text from 'components/Text';

import Card from './';

function PrioritiesCard({
  count,
  hasEmployeeDashboard,
  loginHref,
  isRenderedInIframe,
}) {
  return (
    <Card
      icon={PriorityIcon}
      title={
        <Text values={{ count }} interpolate>
          employee_dashboard__v2__priorities
        </Text>
      }
      description={
        <Text values={{ count }} interpolate>
          employee_dashboard__v2__priorities_desc
        </Text>
      }
      action={
        hasEmployeeDashboard &&
        !isRenderedInIframe && (
          <a
            className="peak-btn peak-btn-secondary peak-btn--sm"
            href={loginHref}
          >
            <Text>employee_dashboard__v2__priorities_see</Text>
          </a>
        )
      }
      data-test-id="priorities-card"
    />
  );
}

PrioritiesCard.propTypes = {
  count: PropTypes.number,
  hasEmployeeDashboard: PropTypes.bool,
  loginHref: PropTypes.string,
  isRenderedInIframe: PropTypes.bool,
};

export default PrioritiesCard;
