import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import api from '../api';

const getAttributeProperties = (response) => {
  return get(response, 'data.attributes', {});
};

const getAttributes = (response) => {
  return get(response, 'data.relationships.attributes', []).map((attr) => ({
    required: attr.attributes.required,
    ...attr.relationships.attribute,
  }));
};

export const getLocalisedAttributes = (responseId) =>
  getSurveyInfo(responseId, {
    attributesOnly: true,
  });

export const getSurveyInfo =
  (responseId, { attributesOnly = false } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const isPreview = state.getIn(['survey', 'isPreview']);
    const locale = state.getIn(['locale', 'locale']);

    let response;

    if (isPreview) {
      const contextId = state.getIn(['survey', 'contextId']);
      const segmentIds = state.getIn(['survey', 'segmentIds']);

      response = await api.schedule.getPreviewInfo(
        contextId,
        { segmentIds },
        locale,
      );
    } else {
      response = await api.response.getInfo(responseId, null, locale);
    }

    const surveyAttributes = getAttributeProperties(response);
    const attributes = getAttributes(response);

    if (attributesOnly) {
      dispatch({
        type: 'ATTRIBUTES_MESSAGE_FETCHED',
        message: surveyAttributes.attributeMessage,
      });

      return dispatch({
        type: 'ATTRIBUTES_FETCHED',
        attributes,
      });
    }

    const data = {
      ...surveyAttributes,
      brandingContext: get(response, 'data.relationships.brandingContext'),

      // attributes
      message: surveyAttributes.attributeMessage,
      attributes,
    };

    return dispatch({
      type: 'SURVEY_INFO_FETCHED',
      data: omitBy(data, isUndefined),
    });
  };
