import { List } from 'immutable';

const features = (state = new List(), action) => {
  switch (action.type) {
    case 'FEATURE_ADDED': {
      return state.push(action.data.feature);
    }
    case 'FEATURE_REMOVED': {
      return state.filter((f) => f !== action.data.feature);
    }
    case 'FEATURES_READ': {
      const { features } = action.data;

      return new List(features);
    }
    default:
      return state;
  }
};

export default features;
