import { composeWithDevTools } from '@redux-devtools/extension';
import { Map } from 'immutable';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';

const enhancers = applyMiddleware(thunk);

export const createTestStore = (initialState) => {
  return createStore(
    reducers,
    new Map(initialState),
    composeWithDevTools(enhancers),
  );
};

export default createStore(reducers, composeWithDevTools(enhancers));
