import featureFactory from 'lib/featureFactory';
export const featureAdded = (name) => ({
  type: 'FEATURE_ADDED',
  data: { feature: name },
});

export const featureRemoved = (name) => ({
  type: 'FEATURE_REMOVED',
  data: { feature: name },
});

export const featuresRead = () => (dispatch, getState) => {
  const state = getState();

  const sessionFeatures = state.getIn(['authenticate', 'session', 'features']);

  const features = featureFactory(
    (sessionFeatures && sessionFeatures.toJS()) || [],
  )
    .filter((feature) => feature.active)
    .map((feature) => feature.key);

  return dispatch({
    type: 'FEATURES_READ',
    data: { features },
  });
};
