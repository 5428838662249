/* eslint-disable no-restricted-imports -- this is the one file we want to allow it */
import { Locales } from '@peakon/localization';

export const employeeLocales = new Locales({
  /** If you update this remember to update `server/middleware/i18n.js` */
  includePrerelease: ENV.enablePrereleaseLanguages,
  employee: true,
});

export { DEFAULT_LOCALE_ID } from '@peakon/localization';
