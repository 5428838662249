import { ESC_KEYCODE } from 'constants/keyCodes';

import React, { useCallback, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

/**
 *  The purpose of this component is to wrap all modals in the application
 *  and provide some default behavior (close on escape, element with initial focus)
 *  out of the box.
 */
const Modal = ({ children, className, onClose, refToFocusOn }) => {
  const ref = useRef();

  const onKeyDown = useCallback(
    (event) => {
      if (event.keyCode === ESC_KEYCODE && onClose) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (ref.current && refToFocusOn && refToFocusOn.current) {
      refToFocusOn.current.focus();
    }

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown, refToFocusOn]);

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  refToFocusOn: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  children: PropTypes.array,
};

export default Modal;
