import React, { Fragment, useState } from 'react';

import classnames from 'classnames';
import startsWith from 'lodash/startsWith';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation, withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { UnstyledButton } from '@peakon/bedrock/react/button';
import { Button, Modal, Typography } from '@peakon/components';

import Text from 'components/Text';
import * as nativeUtil from 'lib/nativeUtil';

import styles from './ErrorPage.scss';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={classnames(styles.root, styles.notFound)}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <p className={styles.notFoundIcon} />
          <div className={styles.title}>{t('not_found__title')}</div>
          <div className={styles.detail}>{t('not_found__detail')}</div>
        </div>
      </div>
    </div>
  );
};

export const ErrorPage = ({
  error,
  onReset,
  resetAvailable,
  responseStatus,
  t,
}) => {
  const [showResetModal, setShowResetModal] = useState(false);

  const renderMessage = () => {
    const status = error && error.status;

    if (!error && responseStatus === 'closed') {
      return t('survey__response_closed');
    } else if (status === -1) {
      return t('notifications__no-connection');
    } else if (startsWith(status, 5) || status === 429) {
      return t('generic_error__text_v2');
    } else {
      return error.message;
    }
  };

  const renderStatusCode = () => {
    const status = error && error.status;
    if (status === -1 || status === 429 || startsWith(status, 5)) {
      return (
        <div className={styles.statusCode}>
          <strong>{t('survey__error__status_code')}</strong>
          {error.status}
        </div>
      );
    }

    return null;
  };

  const { status } = error ?? {};
  nativeUtil.emit(nativeUtil.events.ERROR);
  if (status === 404) {
    return <NotFound />;
  }

  const delimiter = uuidv4();

  const handleCancel = () => {
    setShowResetModal(false);
  };

  return (
    <div className={styles.root} data-test-id="error-page-content">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <h3 className={styles.title}>{renderMessage()}</h3>
            {resetAvailable && (
              <Text
                className={styles.reset}
                preRender={(translated) => {
                  const [start, mid, end] = translated.split(delimiter);

                  return (
                    <Fragment>
                      {start}
                      <UnstyledButton
                        onClick={() => setShowResetModal(true)}
                        accessibleName={mid}
                      >
                        {mid}
                      </UnstyledButton>
                      {end}
                    </Fragment>
                  );
                }}
                type="p"
                values={{
                  link: delimiter,
                }}
              >
                survey__reset
              </Text>
            )}
            {renderStatusCode()}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.links}>
            <span>{t('survey__footer__powered-by-peakon')}</span>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showResetModal}
        onClose={handleCancel}
        title={t('survey__reset__title')}
        testId="reset-modal"
      >
        <Modal.Header>
          <Modal.Title>{t('survey__reset__title')}</Modal.Title>
          <Modal.CloseButton
            closeLabel={t('survey__close')}
            onClick={handleCancel}
          />
        </Modal.Header>
        <Modal.Content>
          <Typography.P>{t('survey__reset__text1')}</Typography.P>
          <Typography.P>{t('survey__reset__text2')}</Typography.P>
        </Modal.Content>
        <Modal.Footer>
          <Button type="secondary" onClick={handleCancel} testId="reset-cancel">
            {t('survey__reset__cancel')}
          </Button>
          <Button async type="danger" onClick={onReset} testId="reset-confirm">
            {t('survey__reset__confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object,
  onReset: PropTypes.func,
  resetAvailable: PropTypes.bool,
  responseStatus: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(ErrorPage);
