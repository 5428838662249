import {
  APP_SUBDOMAIN,
  SURVEY_SUBDOMAIN,
} from './features/subdomains/constants';
import { getCurrentSubdomain } from './features/subdomains/getSubdomain';
import { getSubdomainHref } from './features/subdomains/getSubdomainHref';

function getWebHost() {
  const currentSubdomain = getCurrentSubdomain();

  if (currentSubdomain !== SURVEY_SUBDOMAIN) {
    return getSubdomainHref({
      href: ENV.web.host,
      currentSubdomain: APP_SUBDOMAIN,
      newSubdomain: currentSubdomain,
    });
  }

  return ENV.web.host;
}

export default getWebHost;
