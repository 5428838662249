import Locale from '../records/Locale';

export default function locale(state = new Locale(), action) {
  switch (action.type) {
    case 'BROWSER_LOCALE_SET':
      return state.set('browserLocale', action.browserLocale);

    case 'LOCALE_FORCED':
      return state.set('forcedLocale', true);

    case 'AVAILABLE_LOCALES_SET':
      return state.set('availableLocales', action.availableLocales);

    case 'LOCALE_CHANGED':
      return state.set('locale', action.locale);

    case 'PERSIST_LOCALE_SUCCESS':
      return state.set('persistedLocale', action.locale);

    default:
      return state;
  }
}
