import { SURVEY_SUBDOMAIN } from 'utils/features/subdomains/constants';

import { getCurrentSubdomain } from '../utils/features/subdomains/getSubdomain';

const getAuthTokenKey = () => {
  const currentSubdomain = getCurrentSubdomain();

  return currentSubdomain === SURVEY_SUBDOMAIN
    ? 'auth_token'
    : 'auth_token_survey';
};

export default getAuthTokenKey;
