import React, { useRef, useEffect, useCallback } from 'react';

import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import each from 'lodash/each';
import Mousetrap from 'mousetrap';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';

import { Button, UnstyledButton } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/bedrock/react/spinner';

import * as nativeUtil from 'lib/nativeUtil';

import styles from './Submit.scss';

const KEYBOARD_SHORTCUTS = {
  ESC: ['esc'],
};

type SubmitProps = {
  onCancel: () => void;
  onSubmit: () => Promise<boolean>;
  isMobile?: boolean;
  isPreview?: boolean;
  isSubmitting?: boolean;
  isBelowThreshold?: boolean;
  gotoThankyouPage: () => void;
};

export const Submit = ({
  onCancel,
  isMobile,
  onSubmit,
  isPreview,
  isSubmitting,
  isBelowThreshold,
  gotoThankyouPage,
}: SubmitProps) => {
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const ref = useRef(null);

  const submit = async () => {
    const isSubmitted = await onSubmit();

    if (isSubmitted) {
      Mousetrap.reset();
      gotoThankyouPage();
      nativeUtil.emit(nativeUtil.events.SUBMIT);
    }
  };

  const bindKeyboardShortcuts = useCallback(() => {
    if (!isMobile) {
      Mousetrap.bind(
        KEYBOARD_SHORTCUTS.ESC,
        (event: Mousetrap.ExtendedKeyboardEvent) => {
          event.preventDefault();
          onCancel();
        },
      );
    }
  }, [isMobile, onCancel]);

  const unbindKeyboardShortcuts = useCallback(() => {
    if (!isMobile) {
      each(KEYBOARD_SHORTCUTS, (value) => Mousetrap.unbind(value));
    }
  }, [isMobile]);

  useEffect(() => {
    bindKeyboardShortcuts();

    if (submitButtonRef.current) {
      submitButtonRef.current.focus();
    }

    return () => unbindKeyboardShortcuts();
  }, [bindKeyboardShortcuts, unbindKeyboardShortcuts]);

  const renderActions = () => {
    if (isSubmitting) {
      return (
        <div className={styles.loader} data-test-id="loader">
          <div data-test-id="spinner">
            <Spinner />
          </div>
        </div>
      );
    }

    return (
      <FocusTrap>
        <div className={styles.actions}>
          <div>
            <UnstyledButton
              className={styles.cancel}
              onClick={onCancel}
              data-test-id="change-answer"
              accessibleName={t('survey__navigation__change')}
            >
              {t('survey__navigation__change')}
            </UnstyledButton>
          </div>
          <div className={styles.submit}>
            <Button
              ref={submitButtonRef}
              variant="primary"
              onClick={submit}
              data-test-id="submit-survey"
              size="large"
            >
              {t('survey__submit__submit')}
            </Button>
            {isPreview && (
              <span className={classnames(styles.preview, 'is-negative')}>
                {t('survey__submit__preview_warning')}
              </span>
            )}
          </div>
        </div>
      </FocusTrap>
    );
  };

  return (
    <div>
      <div className={styles.overlay} />
      <div
        ref={ref}
        className={styles.root}
        aria-modal="true"
        aria-labelledby="submit-modal-title"
        aria-describedby="submit-modal-description"
        role="dialog"
        tabIndex={-1}
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <h1 className={styles.title} id="submit-modal-title">
              {t('survey__submit__headline')}
            </h1>

            <div id="submit-modal-description">
              {isBelowThreshold && (
                <p className={styles.warning}>
                  {t('survey__submit-confirm__text')}
                </p>
              )}
              <p>{t('survey__submit__ready')}</p>
            </div>
            <p>{t('survey__submit__back')}</p>
          </div>

          {renderActions()}
        </div>
      </div>
    </div>
  );
};
