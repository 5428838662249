import { Map } from 'immutable';
import isNumber from 'lodash/isNumber';
import trim from 'lodash/trim';

import Answer from '../records/Answer';

const answers = (state = new Map(), action) => {
  switch (action.type) {
    case 'QUESTION_FETCHED': {
      const { question } = action;

      return state.has(question.id)
        ? state
        : state.set(
            question.id,
            new Answer({
              id: question.id,
              questionType: question.attributes.type,
            }),
          );
    }
    case 'SURVEY_RESTORED': {
      const { answers = {} } = action.data;

      return new Map(
        Object.keys(answers).map((i) => [
          answers[i].id,
          new Answer(answers[i]),
        ]),
      );
    }
    case 'QUESTION_SCALE_SET': {
      const { questionId, scale } = action;

      return state.update(questionId, (answer) =>
        answer.merge({
          scale,
          skipReason: null,
        }),
      );
    }
    case 'QUESTION_SCALE_UNSET': {
      const { questionId } = action;

      return state.update(questionId, (answer) =>
        answer.merge({
          scale: undefined,
          showComment: Boolean(answer.comment),
        }),
      );
    }
    case 'QUESTION_SKIPPED': {
      const { questionId, reason } = action;

      return state.update(questionId, (answer) =>
        answer.merge({
          skipReason: reason,
        }),
      );
    }
    case 'QUESTION_SKIP_CANCEL': {
      const { questionId } = action;

      return state.update(questionId, (answer) =>
        answer.set('skipReason', null),
      );
    }
    case 'QUESTION_TOGGLE_COMMENT': {
      const { questionId } = action;

      return state.update(questionId, (answer) =>
        answer.set('showComment', !answer.showComment),
      );
    }
    case 'QUESTION_COMMENTED': {
      const { questionId, comment } = action;

      return state.update(questionId, (answer) =>
        answer.set('comment', trim(comment)),
      );
    }
    case 'QUESTION_ANSWERED': {
      const { answer } = action.data;

      return state.update(answer.id, (item) => {
        if (item.questionType === 'scale' || item.questionType === 'value') {
          const comment = trim(answer.comment);

          return item.merge({
            comment,
            scale: answer.scale,
            showComment: Boolean(
              !answer.keepScaleSelector && (isNumber(answer.scale) || comment),
            ),
          });
        }

        return item.set('text', trim(answer.text));
      });
    }
    default:
      return state;
  }
};

export default answers;
