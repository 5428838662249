import React from 'react';

import PropTypes from 'prop-types';

import { Notification as NotificationComponent } from '@peakon/components';

import styles from './Notification.scss';

const Notification = ({ type, title, message }) => (
  <div className={styles.root} data-test-id={`notification-${type}`}>
    <NotificationComponent type={type}>
      {title && (
        <NotificationComponent.Title>{title}</NotificationComponent.Title>
      )}

      {message && (
        <NotificationComponent.Message>{message}</NotificationComponent.Message>
      )}
    </NotificationComponent>
  </div>
);

Notification.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default Notification;
