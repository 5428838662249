import { OrderedSet } from 'immutable';

import Question, { QuestionState } from '../records/Question';

const ensureOrder = (previous, current) => {
  const sortedArray = previous.toList();

  return current.sort((a, b) => {
    const aIndex = sortedArray.findIndex((p) => p.id === a.id);
    const bIndex = sortedArray.findIndex((p) => p.id === b.id);

    if (aIndex < bIndex) {
      return -1;
    }

    if (aIndex > bIndex) {
      return 1;
    }

    return aIndex - bIndex;
  });
};

const questions = (state = new OrderedSet(), action) => {
  switch (action.type) {
    case 'QUESTION_FETCHED': {
      const { question } = action;

      const immutableQuestion = Question.create(question);

      const isQuestionInState = Boolean(state.get(immutableQuestion));
      if (isQuestionInState) {
        /*
         * The question in state is loaded up from local storage and can contain outdated data.
         * We want to delete the stale data and make sure it is replaced by fresh data from the API
         */
        const updatedState = state.delete(immutableQuestion);
        return updatedState.add(immutableQuestion);
      }

      return state.add(immutableQuestion);
    }
    case 'QUESTIONS_REFETCHED': {
      const { questions } = action.data;

      return ensureOrder(
        state,
        new OrderedSet(questions.data.map(Question.create)),
      );
    }
    case 'SURVEY_RESTORED': {
      const { questions = [] } = action.data;

      return new OrderedSet(questions.map(Question.revive));
    }
    default:
      return state;
  }
};

export default questions;

export const questionState = (state = new QuestionState(), action) => {
  switch (action.type) {
    case 'QUESTION_START':
      return state.set('isLoading', true);
    case 'QUESTION_FETCHED':
      return state.merge({ isLoading: false, error: null });
    case 'QUESTION_SKIP_START':
      return state.set('isSkipping', true);
    case 'QUESTION_CHANGED':
    case 'QUESTION_SKIP_CANCEL':
    case 'QUESTION_SKIPPED':
    case 'QUESTION_ANSWERED':
      return state.set('isSkipping', false);
    case 'QUESTION_ERROR':
      return state.merge({ isLoading: false, error: action.error });
    case 'QUESTIONS_ENDED':
    case 'QUESTION_LOADED':
      return state.set('isLoading', false);
    default:
      return state;
  }
};
