import { SURVEY_SUBDOMAIN } from './constants';
import { isPreviewApp } from '../../isPreviewApp';

/**
 * Extracts the subdomain from the hostname.
 * @param {string} hostname - The hostname to extract the subdomain from.
 * @example
 * getSubdomain('kinetar.peakon.com') // 'kinetar'
 * getSubdomain('kinetar-1234.peakon.com') //'kinetar-1234'
 * getSubdomain('kinetar-1234.preview.peakon.com') // 'kinetar'
 * getSubdomain('kinetar-1234-13345.preview.peakon.com') // 'kinetar-1234'
 **/
export const getSubdomain = (hostname) => {
  // handle preview app URLs `kinetar-1234.preview.staging-peakon.com`
  if (isPreviewApp(hostname)) {
    const subdomain = hostname.split('.')[0];
    // remove the last bit of the string matching the pattern "-number"
    // e.g. kinetar-1234-1234 -> kinetar-1234
    return subdomain.replace(/-\d*$/, '');
  }

  // handle non preview apps URLS like `kinetar.peakon.com` or `kinetar.staging-peakon.com`
  if (/^(?!.*preview).*\..*peakon/.test(hostname)) {
    return hostname.split('.')[0];
  }

  // fallback to the "old" behavior (before subdomains) in case user is trying to access via google translate for example
  return SURVEY_SUBDOMAIN;
};

/**
 * Extracts the current subdomain from the hostname.
 * @example
 * getCurrentSubdomain() // hostname: 'kinetar.peakon.com' ==> 'kinetar'
 * getCurrentSubdomain() // hostname: 'kinetar-1234.peakon.com' ==> 'kinetar-1234'
 * getCurrentSubdomain() // hostname: 'kinetar-1234.preview.peakon.com' ==> 'kinetar'
 * getCurrentSubdomain() // hostname: 'kinetar-1234-13345.preview.peakon.com' ==> 'kinetar-1234'
 **/
export const getCurrentSubdomain = () => {
  return getSubdomain(window.location.hostname);
};
