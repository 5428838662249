import Immutable from 'immutable';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

export const initialState = Immutable.fromJS({
  isAuthenticating: true,
  isAuthenticated: false,
  error: null,
  response: null,
  user: null,
  isSendSMSSuccess: false,
});

export default function authenticate(state = initialState, action) {
  switch (action.type) {
    case 'AUTHENTICATE_START':
      return state.merge({
        isAuthenticating: true,
        error: null,
      });

    case 'AUTHENTICATE_COMPLETE': {
      const { session, response, user, company, account } = action.data;
      return state.merge({
        isAuthenticating: false,
        error: null,
        isAuthenticated: true,
        response,
        user,
        company,
        account,
        session,
      });
    }

    case 'AUTHENTICATE_ERROR': {
      return state.merge({
        isAuthenticating: false,
        error: action.error,
        isAuthenticated: false,
        user: null,
        company: null,
        account: null,
        response: null,
      });
    }

    case 'UNAUTHENTICATE_COMPLETE':
      return state.merge({
        isAuthenticated: false,
        response: null,
      });

    case 'USER_ATTRIBUTES_FETCHED': {
      // filter out null/undefined attributes/relationships
      const data = pickBy(action.data, identity);

      return state.set('user', state.get('user').mergeDeep(data));
    }

    case 'USER_ATTRIBUTES_TRANSLATIONS_FETCHED': {
      const { relationships } = action.data;

      return state.setIn(['user', 'relationships'], relationships);
    }

    case 'SEND_SMS_AUTH_CODE_SUCCESS': {
      return state.merge({
        isSendSMSSuccess: true,
      });
    }

    case 'SEND_SMS_AUTH_CODE_RESET': {
      return state.merge({
        isSendSMSSuccess: false,
      });
    }

    default:
      return state;
  }
}
