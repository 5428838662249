import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';

import { ScoresChangeArrowFallingIcon as ScoreDecrease } from '@peakon/bedrock/icons/graphic';

import { getTranslationFragments } from 'utils/preRender';

import Card from './';

function BottomDriverCard({ summary }) {
  const { t } = useTranslation();

  if (!summary.bottomCategory) {
    return null;
  }

  return (
    <Card
      icon={ScoreDecrease}
      title={getTranslationFragments(t, 'survey__reward__least_satisfied', {
        emphasis: () => (
          <strong key="emphasis" className="is-negative">
            {summary.bottomCategory.name}
          </strong>
        ),
        // this variable is unused due to the `emphasis` above hi-jacking it
        // adding this empty string so the messageFormatProcessor doesn't report a missing variable
        driver: () => '',
      })}
      description={
        <Fragment>
          <strong>{summary.bottomCategory.name}</strong>
          {' – '}
          {summary.bottomCategory.description}
        </Fragment>
      }
      data-test-id="bottom-driver-card"
    />
  );
}

BottomDriverCard.propTypes = {
  summary: PropTypes.object,
};

export default BottomDriverCard;
