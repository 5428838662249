import get from 'lodash/get';
import storejs from 'store';

import { getAttributesWithValue } from 'actions/attributes';
import { featuresRead } from 'actions/features';
import * as nativeUtil from 'lib/nativeUtil';
import { analytics } from 'utils/features/analytics/analytics';
import getAuthTokenKey from 'utils/getAuthTokenKey';
import getWebHost from 'utils/getWebHost';

import { localeRestored, accountLocaleFetched } from './locale';
import { setContext, setKiosk, setKioskCode, setPreview } from './survey';
import { getSurveyInfo } from './surveyInfo';
import api from '../api';
import errorReporter from '../errorReporter';
import { getStoredState } from '../persistor';

export function authenticateStart() {
  return {
    type: 'AUTHENTICATE_START',
  };
}

export function authenticateComplete(data) {
  if (data.session?.mixpanel) {
    analytics.identifyUser(data.session.mixpanel);
  }
  return {
    type: 'AUTHENTICATE_COMPLETE',
    data,
  };
}

export function authenticateError(error) {
  return {
    type: 'AUTHENTICATE_ERROR',
    error,
  };
}

// Transform data from localStorage
// https://github.com/rt2zz/redux-persist#transforms
const transformer = ({
  answers = {},
  questionsV2 = [],
  info,
  context,
  coverInfo,
  survey,
} = {}) => ({
  answers,
  info,
  context,
  coverInfo: coverInfo || info,
  questions: questionsV2,
  survey,
});

const loadStoredState =
  (responseId, transform = transformer) =>
  (dispatch) => {
    const stored = getStoredState(responseId);

    if (!stored) {
      return;
    }

    const data = transform(stored);

    dispatch({
      type: 'SURVEY_RESTORED',
      data,
    });

    // This must be restored separately, as it has side-effects that
    // have to be executed separately
    if (stored.locale) {
      dispatch(localeRestored(stored.locale));
    }

    return data;
  };

export function getSessionData() {
  return async function (dispatch) {
    let session;

    try {
      session = await api.root.session({
        include: 'company,account,employee,company.companySubdomains',
        fields: {
          companies:
            'accessAllowed,code,createdAt,employeeFeatures,features,location,logo,name,sector,size,settings,addOns,companySubdomains',
        },
      });
    } catch (err) {
      dispatch(authenticateError(err));
    }

    const accountId = get(session, 'data.relationships.account.id');

    if (accountId) {
      errorReporter.updateConfig({ person: { id: accountId } });
    }

    return session;
  };
}

export function completeAuthentication(responseId) {
  return async function (dispatch, getState) {
    const state = getState();

    const session = await dispatch(getSessionData());
    const isKiosk = state.getIn(['survey', 'isKiosk']);
    const isNativeApp = getState().getIn(['ui', 'isNative']);

    if (session) {
      const storedState = await dispatch(loadStoredState(responseId));

      if (!storedState || !storedState.locale) {
        const locale = get(
          session,
          'data.relationships.account.attributes.localeEffective',
        );

        if (locale && !isKiosk && !isNativeApp) {
          dispatch(accountLocaleFetched(locale));
        }
      }

      await dispatch(getSurveyInfo(responseId));

      const addOns =
        session.data.relationships.company?.attributes?.addOns ?? [];

      const hasKioskConsentAttributes = addOns.includes(
        'kiosk_consent_attributes',
      );

      if (isKiosk && hasKioskConsentAttributes) {
        await dispatch(getAttributesWithValue(responseId));
      }

      dispatch(
        authenticateComplete({
          session: session.data.attributes,
          user: session.data.relationships.employee,
          account: session.data.relationships.account,
          company: session.data.relationships.company,
          response: {
            responseId,
          },
        }),
      );

      dispatch(featuresRead());
    }
  };
}

export function authenticateFromSession(isPreview, isKiosk) {
  return async function (dispatch) {
    dispatch(authenticateStart());

    if (!api.auth.isAuthenticated()) {
      const authToken = storejs.get(getAuthTokenKey());
      api.auth.authenticate(authToken);
    }

    const responseId = isPreview ? 'preview' : storejs.get('response_id');

    let scheduleCode;
    if (isKiosk) {
      scheduleCode = storejs.get('schedule_code');
    }

    if (api.auth.isAuthenticated() && responseId) {
      if (isPreview) {
        const previewContext = storejs.get('preview_context');
        if (previewContext) {
          dispatch(
            setContext(previewContext.contextId, previewContext.segmentIds),
          );
        }
        dispatch(setPreview());
      } else if (isKiosk) {
        if (!scheduleCode) {
          return window.location.assign(`${getWebHost()}/login`);
        }

        const kioskData = storejs.get(`kiosk_${scheduleCode}`);
        if (!kioskData) {
          return window.location.assign(`/survey/kiosk/${scheduleCode}`);
        }

        dispatch(setKiosk());
        dispatch(setKioskCode(kioskData.scheduleCode, kioskData.kioskCode));
      }

      await dispatch(completeAuthentication(responseId));
    } else if (isKiosk && scheduleCode) {
      window.location.assign(`/survey/kiosk/${scheduleCode}`);
    } else {
      window.location.assign(`${getWebHost()}/login`);
    }

    nativeUtil.emit(nativeUtil.events.AUTHENTICATED);
  };
}

export function authenticate(token) {
  return async function (dispatch) {
    dispatch(authenticateStart());

    let response;
    try {
      response = await api.auth.authenticateByToken(token);
    } catch (err) {
      dispatch(authenticateError(err));
    }

    const responseId = get(response, 'data.relationships.source.id');
    if (responseId) {
      await dispatch(completeAuthentication(responseId));
    }

    nativeUtil.emit(nativeUtil.events.AUTHENTICATED);
  };
}

export function unauthenticateComplete() {
  analytics.clearEnrichmentData();
  return {
    type: 'UNAUTHENTICATE_COMPLETE',
  };
}

export function unauthenticate() {
  return async function (dispatch) {
    const scheduleCode = storejs.get('schedule_code');
    if (scheduleCode) {
      storejs.remove(`kiosk_${scheduleCode}`);
    }
    await api.auth.unauthenticate();
    storejs.remove(getAuthTokenKey());
    dispatch(unauthenticateComplete());
  };
}

export function userAttributesFetched(data) {
  return {
    type: 'USER_ATTRIBUTES_FETCHED',
    data,
  };
}

export function userAttributesTranslationsFetched(data) {
  return {
    type: 'USER_ATTRIBUTES_TRANSLATIONS_FETCHED',
    data,
  };
}

export function sendSMSSuccess() {
  return {
    type: 'SEND_SMS_AUTH_CODE_SUCCESS',
  };
}

export function sendSMSReset() {
  return {
    type: 'SEND_SMS_AUTH_CODE_RESET',
  };
}

export const postGenerateCode = (phoneNumber) => async (dispatch) => {
  try {
    await api.post('/mobile/app_link', null, {
      phoneNumber,
    });
    dispatch(sendSMSSuccess());
  } catch {
    dispatch(sendSMSReset());
  }
};
