import { createSelector } from 'reselect';

import Answer from 'records/Answer';

const answerSelector = (state) => state.get('answers');
const questionsSelector = (state) => state.get('questions');
const surveySelector = (state) => state.get('survey');

export const getQuestionsWithAnswers = createSelector(
  [questionsSelector, answerSelector],
  (questions, answers) =>
    questions.map((question) =>
      question.set(
        'answer',
        answers.get(
          question.id,
          new Answer({ id: question.id, questionType: question.type }),
        ),
      ),
    ),
);

export const getCurrentQuestion = createSelector(
  [surveySelector, getQuestionsWithAnswers],
  (survey, questions) =>
    questions.find((question) => question.id === survey.currentQuestion),
);

export const getCurrentQuestionGroup = createSelector(
  [surveySelector, getQuestionsWithAnswers],
  (survey, questions) =>
    questions.find((question) => question.id === survey.currentQuestion)?.group,
);

export const getCurrentQuestionIndex = createSelector(
  [getCurrentQuestion, questionsSelector],
  (current, questions) => {
    if (!current) {
      return null;
    }

    const questionList = questions.toList();

    return questionList.findIndex((question) => question.id === current.id);
  },
);

export const getPreviousQuestion = createSelector(
  [getCurrentQuestion, getQuestionsWithAnswers],
  (current, questions) => {
    if (!current) {
      return null;
    }

    const questionList = questions.toList();
    const index = questionList.findIndex(
      (question) => question.id === current.id,
    );
    const prevIdx = index > 0 ? index - 1 : null;

    return questionList.get(prevIdx, null);
  },
);

export const getNextQuestion = createSelector(
  [getCurrentQuestion, getQuestionsWithAnswers],
  (current, questions) => {
    if (!current) {
      return null;
    }

    const questionList = questions.toList();
    const index = questionList.findIndex(
      (question) => question.id === current.id,
    );

    const nextIndex = index + 1 < questionList.size ? index + 1 : null;

    return questionList.get(nextIndex, null);
  },
);
