import { Context } from 'records';

const context = (state = new Context(), action) => {
  switch (action.type) {
    case 'SCHEDULE_READ':
      return Context.create(action.data.schedule.data);
    default:
      return state;
  }
};

export default context;
