import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Card as CardComponent } from '@peakon/components';

import ActionsCard from './ActionsCard';
import BottomDriverCard from './BottomDriverCard';
import LearnMoreCard from './LearnMoreCard';
import PrioritiesCard from './PrioritiesCard';
import ScoreChangeCard from './ScoreChangeCard';
import styles from './styles.scss';
import TopDriverCard from './TopDriverCard';

const Card = ({
  className,
  icon: IconComponent,
  title,
  description,
  action = null,
  ...props
}) => {
  return (
    <CardComponent
      className={classnames(styles.root, {
        [className]: Boolean(className),
      })}
      {...props}
    >
      <div className={styles.icon}>{IconComponent && <IconComponent />}</div>
      <div className={classnames(styles.content)}>
        <div className={styles.text}>
          <h2 className={classnames('peak-typo--h4', styles.title)}>{title}</h2>
          {description && <p className="peak-typo--text">{description}</p>}
        </div>
        {action && <div className={styles.action}>{action}</div>}
      </div>
    </CardComponent>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  action: PropTypes.node,
};

export default Card;
export {
  PrioritiesCard,
  ActionsCard,
  TopDriverCard,
  BottomDriverCard,
  LearnMoreCard,
  ScoreChangeCard,
};
