import { Component } from 'react';

import PropTypes from 'prop-types';

import errorReporter from '../../errorReporter';

class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  componentDidCatch(error) {
    this.setState({
      error,
    });

    errorReporter.error(error);
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      if (fallback) {
        return typeof fallback === 'function' ? fallback(error) : null;
      }

      return null;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  fallback: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default ErrorBoundary;
