import api from '../api';

export function summaryFetched(summary) {
  return {
    type: 'SUMMARY_FETCHED',
    summary,
  };
}

export function summaryError() {
  return {
    type: 'SUMMARY_ERROR',
  };
}

export function getSummary() {
  return async function (dispatch, getState) {
    const state = getState();
    const isPreview = state.getIn(['survey', 'isPreview']);
    const contextId = state.getIn(['survey', 'contextId']);

    if (isPreview) {
      const summary = await api.get(
        `/schedules/contexts/${contextId}/preview/summary`,
      );
      dispatch(summaryFetched(summary.data));
    } else {
      const responseId = state.getIn([
        'authenticate',
        'response',
        'responseId',
      ]);

      try {
        const response = await api.response.summary(responseId);
        dispatch(summaryFetched(response.data));
      } catch (err) {
        dispatch(summaryError());
        throw err;
      }
    }
  };
}
