import React from 'react';
import { CSSTransition } from 'react-transition-group';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';

import {
  Spinner,
  Button,
  Notification as NotificationComponent,
} from '@peakon/components';

import { Context } from 'records';
import KioskEmployeeLogin from 'components/KioskEmployeeLogin';
import KioskCodeInput from 'components/KioskCodeInput';
import Footer from 'components/Footer';

import transitions from '../../containers/Survey/transitions.scss';
import styles from './KioskForm.scss';
import { KioskMode } from 'constants/kiosk';

export const KioskForm = ({
  kioskMode,
  isSubmitting = false,
  handleSubmit,
  error,
  isAuthenticating = false,
  context,
  showNotification = false,
  notification,
}: {
  kioskMode: KioskMode;
  isSubmitting: boolean;
  handleSubmit: () => void;
  error: { type: string; error: Error };
  isAuthenticating: boolean;
  context: Context;
  showNotification: boolean;
  notification: object;
}) => {
  const { t } = useTranslation();

  switch (kioskMode) {
    case 'ephemeral': {
      return (
        <div className={styles.ephemeral}>
          <h1 className={styles.title}>
            {t('survey__welcome__headline', {
              company: context.name,
            })}
          </h1>

          <Button
            disabled={isSubmitting}
            onClick={handleSubmit}
            testId="survey-start"
            type="primary"
          >
            {isSubmitting ? (
              <Spinner color="white" size="small" />
            ) : (
              t('survey__navigation__start')
            )}
          </Button>
          <div className={styles.footer}>
            <Footer />
          </div>

          {showNotification && (
            <CSSTransition classNames={transitions} appear timeout={500}>
              <NotificationComponent {...notification} />
            </CSSTransition>
          )}
        </div>
      );
    }

    case 'employeeLogin': {
      return (
        <KioskEmployeeLogin
          onSubmit={handleSubmit}
          error={!isAuthenticating && !isSubmitting && error}
          isSubmitting={isSubmitting}
        />
      );
    }

    default: {
      return (
        <KioskCodeInput
          onSubmit={handleSubmit}
          error={!isAuthenticating && !isSubmitting && error}
          isSubmitting={isSubmitting}
          mode={kioskMode}
        />
      );
    }
  }
};
