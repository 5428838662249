import React, { useEffect } from 'react';

import { createBrowserHistory } from 'history';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { ThemeProvider } from '@peakon/components';

import { analytics } from 'utils/features/analytics/analytics';

import CheckRedirectToSubdomain from './CheckRedirectToSubdomain';
import { configureApi } from '../../api';
import store from '../../createStore';
import i18n from '../../features/i18n/i18n';
import createPersistor from '../../persistor';
import { Routes } from '../../routes';
import ErrorBoundary from '../ErrorBoundary';
import ErrorPage from '../ErrorPage';
// installs error handler to api
configureApi(store.dispatch);

// FIXME: use redux-persist
// install local storage persistor
const persistor = createPersistor(store.getState, 500);
store.subscribe(persistor);

const browserHistory = createBrowserHistory();

const LOADING_TIMEOUT = 200;
const LOADING_ERROR_TIMEOUT = LOADING_TIMEOUT + 200;

const App = () => {
  analytics.init();

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      window.document.body.classList.remove('loading');
    }, LOADING_TIMEOUT);

    const errorTimeout = setTimeout(() => {
      const elm = window.document.getElementById('loading-container');
      const errorElm = window.document.getElementById('error-container');

      elm && elm.parentNode?.removeChild(elm);
      errorElm && errorElm.parentNode?.removeChild(errorElm);
    }, LOADING_ERROR_TIMEOUT);

    return () => {
      clearTimeout(loadingTimeout);
      clearTimeout(errorTimeout);
    };
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary fallback={(error) => <ErrorPage error={error} />}>
          <ThemeProvider theme="survey">
            <CheckRedirectToSubdomain>
              <Router history={browserHistory}>
                <Routes />
              </Router>
            </CheckRedirectToSubdomain>
          </ThemeProvider>
        </ErrorBoundary>
      </I18nextProvider>
    </Provider>
  );
};

export default App;
