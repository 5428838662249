import { fromJS } from 'immutable';

const initialState = fromJS({
  isLoading: false,
  isLoadingValues: false,
  data: [],
  attributesWithValue: [],
  showForm: false,
  error: null,
  message: null,
});

export default function attributes(state = initialState, action) {
  switch (action.type) {
    case 'ATTRIBUTES_START':
      return state.set('isLoading', true);

    case 'ATTRIBUTES_END':
      return state.set('isLoading', false);

    case 'ATTRIBUTES_CHECKED':
      return state.merge({
        showForm: action.status !== 'ok',
      });

    case 'ATTRIBUTES_FETCHED': {
      return state.set('data', fromJS(action.attributes));
    }

    case 'ATTRIBUTES_WITH_VALUE_FETCHED': {
      return state.set('attributesWithValue', fromJS(action.attributes));
    }

    case 'SURVEY_INFO_FETCHED': {
      const { attributes = [], message } = action.data;

      return state.merge({
        data: fromJS(attributes),
        message,
      });
    }

    case 'ATTRIBUTES_ERROR':
      return state.merge({
        isLoading: false,
        error: action.error,
      });

    case 'ATTRIBUTES_SUBMIT_COMPLETE':
      return state.set('showForm', false);

    case 'ATTRIBUTES_MESSAGE_FETCHED':
      return state.set('message', action.message);

    case 'ATTRIBUTES_VALUES_START':
      return state.set('isLoadingValues', true);

    case 'ATTRIBUTES_VALUES_END':
      return state.set('isLoadingValues', false);

    case 'ATTRIBUTES_SET_READ_ONLY':
      return state.set(
        'data',
        state
          .get('data')
          .map((attribute) =>
            attribute.set(
              'readOnly',
              action.attributes.includes(
                attribute.getIn(['attributes', 'name']),
              ),
            ),
          ),
      );

    default:
      return state;
  }
}
