import React, { useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { withTranslation } from 'react-i18next';

import { NavigationBasicNavigationArrowRightIcon as ArrowIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';

import styles from './KioskEmployeeLogin.scss';

const KioskEmployeeLogin = ({ error, onSubmit, isSubmitting, t }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [employeeId, setEmployeeId] = useState('');

  const handleFirstNameChange = (e) => {
    const firstName = e.target.value;
    setIsValid(firstName && employeeId);
    setHasChanges(true);
    setFirstName(firstName);
  };

  const handleEmployeeIdChange = (e) => {
    const employeeId = e.target.value;

    setIsValid(employeeId && firstName);
    setHasChanges(true);
    setEmployeeId(employeeId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    if (isValid) {
      setHasChanges(false);
      if (onSubmit) {
        onSubmit(employeeId, firstName);
      }
    }
  };

  const renderError = () => {
    if (!error || hasChanges) {
      return null;
    }

    return <div className={styles.errorMessage}>{error.message}</div>;
  };

  const renderForm = () => {
    const hasError = error && !hasChanges;

    return (
      <div className={styles.form}>
        <div className={classnames('peak-form-field', styles.formCol)}>
          <label
            htmlFor="employeeId"
            className={classnames('peak-form-label', styles.formTitle)}
          >
            {t('survey__kiosk__enter_employee_id')}
          </label>
          <div className="peak-form-control">
            <input
              key="employeeId"
              id="employeeId"
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              autoFocus
              className={classnames('peak-form-text', styles.input, {
                'peak-form-text--error': hasError,
              })}
              disabled={isSubmitting}
              onChange={handleEmployeeIdChange}
              placeholder={t('survey__kiosk__enter_employee_id__placeholder')}
              spellCheck="false"
              type="text"
              value={employeeId}
              data-test-id="employee-id"
            />
          </div>
        </div>
        <div className={classnames('peak-form-field', styles.formCol)}>
          <label
            htmlFor="firstName"
            className={classnames('peak-form-label', styles.formTitle)}
          >
            {t('survey__kiosk__enter_first_name')}
          </label>
          <div className="peak-form-control">
            <input
              key="firstName"
              id="firstName"
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              className={classnames('peak-form-text', styles.input, {
                'peak-form-text--error': hasError,
              })}
              disabled={isSubmitting}
              onChange={handleFirstNameChange}
              placeholder={t('survey__kiosk__enter_first_name__placeholder')}
              spellCheck="false"
              type="text"
              value={firstName}
              data-test-id="employee-name"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <form
        method="POST"
        onSubmit={handleSubmit}
        className={classnames(styles.form, {
          [styles.valid]: isValid,
        })}
      >
        {renderForm()}
        <UnstyledButton
          type="submit"
          accessibleName={t('survey__navigation__start')}
          className={styles.button}
          disabled={isSubmitting || (error && !hasChanges)}
          data-test-id="login-btn"
        >
          <ArrowIcon className={styles.arrow} />
        </UnstyledButton>
      </form>
      {renderError()}
    </div>
  );
};

KioskEmployeeLogin.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(KioskEmployeeLogin);
