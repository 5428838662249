import { Record } from 'immutable';
import identity from 'lodash/identity';
import isNumber from 'lodash/isNumber';
import pickBy from 'lodash/pickBy';

export default class Answer extends Record({
  id: undefined,
  scale: undefined,
  skipReason: undefined,
  showComment: false,
  comment: undefined,
  text: undefined,
  questionType: undefined,
  keepScaleSelector: false,
}) {
  isAnswered() {
    return Boolean(isNumber(this.scale) || this.text || this.comment);
  }

  isAnsweredOrSkipped() {
    return this.isAnswered() || this.isSkipped();
  }

  isSkipped() {
    return !this.isAnswered() && typeof this.skipReason !== 'undefined';
  }

  static create(data) {
    const {
      attributes,
      relationships: {
        question: {
          data: { id, questionType },
        },
      },
    } = data;

    return new Answer(
      Object.assign({}, attributes, { id: Number(id), questionType }),
    );
  }

  toJSONApi() {
    const { id, scale, comment, text, skipReason } = this.toJSON();

    return {
      type: 'answers',
      attributes: pickBy(
        {
          scale,
          comment,
          text,
          skipReason: isNumber(scale) || comment || text ? null : skipReason,
        },
        (value) => identity(value) || isNumber(value),
      ),
      relationships: {
        question: {
          data: {
            type: 'questions',
            id: Number(id),
          },
        },
      },
    };
  }
}
