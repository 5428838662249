import getWebHost from 'utils/getWebHost';

export function getLoginHref(activationState) {
  const {
    email,
    companyId,
    sso: { url: ssoUrl, required: ssoRequired } = {},
    activationToken,
  } = activationState || {};

  if (ssoRequired) {
    return ssoUrl;
  }

  const query = new URLSearchParams({
    redirectTo: '/employee-dashboard',
  }).toString();

  // ensure we encode the email in case of `+` chars etc.
  const encodedEmail = encodeURIComponent(email);

  const path = activationToken ? '/login/activation' : '/employee-dashboard';
  let baseUrl = window.ENV
    ? `${getWebHost()}${path}?${query}#email=${encodedEmail}&companyId=${companyId}`
    : '/';

  if (activationToken) {
    baseUrl = `${baseUrl}&token=${activationToken}`;
  }

  return baseUrl;
}
