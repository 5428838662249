import Summary from '../records/Summary';

export default function summary(state = new Summary(), action) {
  switch (action.type) {
    case 'SUMMARY_FETCHED':
      return Summary.create(action.summary, state.enabled);
    case 'SUMMARY_ERROR':
      return state.merge({
        enabled: false,
        isLoading: false,
      });

    case 'SURVEY_INFO_FETCHED': {
      const { summary } = action.data;

      return state.set('enabled', summary);
    }

    case 'SUMMARY_ENABLED':
      return state.set('enabled', true);
    default:
      return state;
  }
}
