import React, { forwardRef, useRef, useEffect } from 'react';

import classnames from 'classnames';
import times from 'lodash/times';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';

import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';

import styles from './styles.scss';

const Button = ({ checked, value, onClick, disabled }) => {
  const { t } = useTranslation();
  const getRadioLabelKey = (score) => {
    if (score === 0) {
      return t('survey__a11y_score-min', { score, total: 10 });
    } else if (score === 10) {
      return t('survey__a11y_score-max', { score, total: 10 });
    }
    return t('survey__score', { score, total: 10 });
  };
  const ref = useRef(null);

  useEffect(() => {
    if (checked && ref.current) {
      // @ts-expect-error Property 'value' does not exist on type 'never'
      ref.current.focus();
    }
  }, [checked]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const score = parseInt(value, 10);
    if (onClick) {
      onClick(score);
    }
  };

  const label = getRadioLabelKey(value);

  return (
    <button
      ref={ref}
      key={value}
      type="button"
      disabled={disabled}
      aria-labelledby={`score-label-${value}`}
      className={classnames(styles.button, {
        [styles.checked]: checked,
      })}
      aria-pressed={checked}
      data-scale={value}
      onClick={handleClick}
    >
      <div className={styles.buttonInner} aria-hidden="true">
        {value}
      </div>
      <VisuallyHidden id={`score-label-${value}`}>{label}</VisuallyHidden>
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.number,
};

const Group = ({ forwardedRef, onClick, labelledBy, value, isAnimating }) => {
  let transformOrigin;

  if (isAnimating && Boolean(forwardedRef.current?.children[value])) {
    transformOrigin = forwardedRef.current.children[value].offsetWidth * value;
  }

  return (
    <div
      ref={forwardedRef}
      role="group"
      aria-labelledby={labelledBy}
      className={classnames(styles.group, {
        [styles.animate]: isAnimating,
      })}
      style={{ transformOrigin }}
      tabIndex="-1"
    >
      {times(11).map((score) => {
        return (
          <Button
            key={score}
            disabled={isAnimating}
            onClick={onClick}
            checked={value === score}
            value={score}
          />
        );
      })}
    </div>
  );
};

Group.propTypes = {
  forwardedRef: PropTypes.any,
  isAnimating: PropTypes.bool,
  labelledBy: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.number,
};

export const ButtonGroup = forwardRef((props, ref) => (
  <Group forwardedRef={ref} {...props} />
));

ButtonGroup.displayName = 'ButtonGroup';
