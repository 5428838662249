import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const Portal = ({ children, node = window.document.body }) => {
  return createPortal(children, node);
};

Portal.propTypes = {
  children: PropTypes.node,
  node: PropTypes.any,
};

export default Portal;
