import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import Text from 'components/Text';

import styles from './styles.scss';

const Tooltip = ({ id, comment, question, skipReason, value }) => (
  <div className={styles.root} role="tooltip" id={id}>
    <div className={styles.triangle} aria-hidden="true">
      ▶
    </div>
    <div>
      {value && (
        <Fragment>
          <span className={styles.value}>{value}</span> -{' '}
        </Fragment>
      )}
      {question}
    </div>
    {comment && (
      <div className={styles.section}>
        <Text className={styles.title} type="div">
          survey__progress__your_comment
        </Text>
        {comment}
      </div>
    )}
    {skipReason && (
      <div className={styles.section}>
        <Text className={styles.title} type="div">
          survey__progress__skipped
        </Text>
        <Text>{`survey__skip__${skipReason}`}</Text>
      </div>
    )}
  </div>
);

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  comment: PropTypes.string,
  question: PropTypes.string.isRequired,
  skipReason: PropTypes.string,
  value: PropTypes.string,
};

export default Tooltip;
