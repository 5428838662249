import get from 'lodash/get';
import storejs from 'store';

import Peakon from '@peakon/peakon-js';

import { SURVEY_SUBDOMAIN } from 'utils/features/subdomains/constants';
import { getCurrentSubdomain } from 'utils/features/subdomains/getSubdomain';
import getAuthTokenKey from 'utils/getAuthTokenKey';
import { isPreviewApp } from 'utils/isPreviewApp';

import { showErrorNotification } from './actions/notification';
import errorReporter from './errorReporter';

export const getBaseUrl = () => {
  const subdomain = getCurrentSubdomain();

  if (subdomain === SURVEY_SUBDOMAIN) {
    return ENV.api;
  }

  if (isPreviewApp()) {
    return window.location.origin.replace(/-\d*.preview/, '');
  }

  return window.location.origin;
};

const api = new Peakon({
  baseUrl: getBaseUrl(),
  retryAuthUsingParam: true,
  testAgent: storejs.get('peakon.testAgent'),
  authTokenKey: getAuthTokenKey(),
});

export const configureApi = (dispatch) => {
  function onError(error, data) {
    errorReporter.error(data.message, error);

    dispatch(
      showErrorNotification({
        message:
          get(data, 'message') ||
          "An unknown error occurred — if the problem doesn't go away, contact your admin for help.",
      }),
    );
  }

  return api.configure({
    onError,
  });
};

export default api;
