import React from 'react';

import MessageFormat, { FormatXMLElementFn } from 'intl-messageformat';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import errorReporter from '../../errorReporter';
import { DEFAULT_LOCALE_ID } from './localesHelper';
// eslint-disable-next-line no-restricted-imports -- this is the one place where we want to allow the use of `useTranslation` from `react-i18next`
import { useTranslation } from 'react-i18next';

type AnyReactElement = React.ReactElement<
  unknown,
  string | React.JSXElementConstructor<unknown>
>;

/**
 * Branded translation string schema. Can be used to parse a string to a translation string.
 */
export const translatedStringSchema = z.string().brand('TranslatedString');

/**
 * Branded translation string type. Can be used to cast a string to a translation string.
 *
 * For more flexibility in component props, use `TranslatedContent` instead.
 */
export type TranslatedString = z.infer<typeof translatedStringSchema>;

/**
 * Return type of translation strings in which we interpolate components.
 *
 * For more flexibility in component props, use `TranslatedContent` instead.
 */
export type TranslatedStringWithInterpolatedComponents =
  | TranslatedString
  | (TranslatedString | AnyReactElement)[];

/**
 * Return type of the `t` function.
 *
 * Good to use when we want to ensure that props passed to components are translated.
 */
export type TranslatedContent =
  | TranslatedString
  | TranslatedStringWithInterpolatedComponents;

type Options = {
  ns?: string;
  replace?: Record<string, unknown>;
  components?: Record<string, React.JSX.Element>;
};

/**
 * TODO: Currently survey does have any mechanisms in place to guarantee that the entire app will re-renders with the correct language when switching languages,
 * so we need to rely on the `useTranslation` hook to ensure that individual components re-render with the correct language.
 * Once https://jira2.workday.com/browse/PEAKONLEAD-3494 gets taken care of, we can remove the `useT` hook and expose the `t` function directly, like we do in dashboard.
 */

/**
 * Hook to return `t` function used to translate strings.
 *
 * See our {@link https://confluence.workday.com/pages/viewpage.action?pageId=1582152134 Localization documentation}.
 * @example
 * ```tsx
 * const t = useT();
 *
 * t('signup__welcome', {
 *  replace: { name: 'John Doe' },
 *  components: { bold: <strong /> },
 * });
 * ```
 *
 */
export const useT = () => {
  const { t: i18nextTranslate } = useTranslation();
  function t(key: string): TranslatedString;
  function t(
    key: string,
    options: { components?: undefined } & Options,
  ): TranslatedString;
  function t(
    key: string,
    options: {
      components: Record<string, React.JSX.Element>;
    } & Options,
  ): TranslatedStringWithInterpolatedComponents;

  function t(key: string, options?: Options | undefined): TranslatedContent {
    const postProcess = ['messageFormat'];

    return i18nextTranslate(`${key}.message`, {
      ...options,
      replace: {
        ...options?.replace,
        ...getMessageFormatComponents(options?.components),
      },
      postProcess,
      skipInterpolation: true,
      // Casting since the result from i18next is always a string,
      // but we want to brand that string and take into account that we can interpolate components into it
    }) as TranslatedContent;
  }
  return t;
};

/**
 * Provides a placeholder for text requiring translation but lacking final copy.
 *
 * Using this function instead of hardcoding English strings simplifies the process of finding all text requiring translation
 * by enabling engineers to search for usages of this function.
 *
 * Supports variable and component interpolation, mirroring the `t` function's behavior.
 *
 * @param englishCopy - Placeholder or near-final text awaiting approval.
 * @param options.replace - Variables to interpolate into the string.
 * @param options.components - Components to interpolate into the string.
 *
 * @example
 * ```tsx
 * todoT('Welcome <bold>{name}</bold>!', {
 *   replace: { name: 'Zoe' },
 *   components: { bold: <strong /> },
 * });
 * ```
 */
export function todoT(englishCopy: string): TranslatedString;
export function todoT(
  englishCopy: string,
  options: { components?: undefined } & Options,
): TranslatedString;
export function todoT(
  englishCopy: string,
  options: {
    components: Record<string, React.JSX.Element>;
  } & Options,
): TranslatedStringWithInterpolatedComponents;

export function todoT(
  englishCopy: string,
  options?: Options | undefined,
): TranslatedContent {
  try {
    /*
     * Using `DEFAULT_LOCALE_ID`, since that's what our hardcoded English strings are in.
     * Using the current locale could cause issues if the locale requires multiple plural forms and those aren't present in the English copy.
     */

    return new MessageFormat(englishCopy, DEFAULT_LOCALE_ID).format({
      ...options?.replace,
      ...getMessageFormatComponents(options?.components),
      // Casting since the result from MessageFormat is always a string,
      // but we want to brand that string and take into account that we can interpolate components into it
    }) as TranslatedContent;
  } catch (e: unknown) {
    if (e instanceof Error) {
      errorReporter.error(e);
    }
  }

  return englishCopy as TranslatedContent;
}

type MessageFormatComponents = Record<
  string,
  FormatXMLElementFn<React.ReactNode>
>;
/**
 * Converts the simplified components object to the expected format for `i18next`.
 */
function getMessageFormatComponents(components: Options['components']) {
  const messageFormatComponents: MessageFormatComponents = {};

  if (!components) {
    return messageFormatComponents;
  }

  for (const [componentKey, componentValue] of Object.entries(components)) {
    messageFormatComponents[componentKey] = (translation) =>
      React.createElement(
        componentValue.type,
        // Adds a key prop to each component to ensure React can render them correctly.
        { key: uuidv4(), ...componentValue.props },
        translation,
      );
  }

  return messageFormatComponents;
}
