import isFunction from 'lodash/isFunction';

export function getCurrentQuestion(state) {
  const currentQuestion = state
    .getIn(['questions', 'data'])
    .filter((q) => q.get('current'))
    .get(0);
  if (currentQuestion) {
    return currentQuestion.toJS();
  } else {
    return null;
  }
}

export function getPreviousQuestion(state) {
  const questionIndex = state
    .getIn(['questions', 'data'])
    .findIndex((q) => q.get('current'));
  const previousQuestion =
    questionIndex > 0 && state.getIn(['questions', 'data', questionIndex - 1]);
  if (previousQuestion) {
    return previousQuestion.toJS();
  } else {
    return null;
  }
}

export function getNextQuestion(state) {
  const questions = state.getIn(['questions', 'data']);
  const questionIndex = questions.findIndex((q) => q.get('current'));
  const nextIndex = questionIndex + 1;
  const nextQuestion =
    nextIndex < questions.size && state.getIn(['questions', 'data', nextIndex]);
  if (nextQuestion) {
    return nextQuestion.toJS();
  } else {
    return null;
  }
}

export function toJS(path, state) {
  const value = state.getIn(path);
  if (value) {
    if (isFunction(value.toJS)) {
      return value.toJS();
    } else {
      return value;
    }
  } else {
    return null;
  }
}
