import { Record, fromJS } from 'immutable';

import Answer from './Answer';

export const Value = Record({
  id: undefined,
  name: undefined,
  description: undefined,
});

export default class Question extends Record({
  id: undefined,
  customized: false,
  driver: undefined,
  subdriver: undefined,
  text: undefined,
  status: undefined,
  schedule: undefined,
  type: undefined,
  frequency: undefined,
  standard: true,
  value: undefined,
  answer: new Answer(),
  identifier: undefined,
  illustration: undefined,
  group: undefined,
}) {
  static revive(data) {
    const { type } = data;

    const value = type === 'value' ? new Value(fromJS(data.value)) : undefined;

    return new Question(
      fromJS(
        Object.assign(
          {},
          data,
          { answer: new Answer({ id: data.id, questionType: data.type }) },
          { value },
          { group: type?.category?.attributes?.group },
        ),
      ),
    );
  }

  static create(data) {
    const {
      id,
      attributes,
      relationships: { value: valueData, category } = {},
    } = data;

    const value = valueData
      ? new Value({
          id: valueData.id,
          name: valueData.attributes.name,
          description: valueData.attributes.description,
        })
      : undefined;

    return new Question(
      fromJS(
        Object.assign(
          {},
          { id },
          attributes,
          {
            answer: new Answer({ id, questionType: attributes.type }),
          },
          { value },
          { group: category?.attributes?.group },
        ),
      ),
    );
  }

  hashCode() {
    return this.id;
  }

  equals(other) {
    return other.id === this.id;
  }
}

export const QuestionState = Record({
  isLoading: false,
  error: null,
  isSkipping: false,
});
