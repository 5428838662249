import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { SURVEY_SUBDOMAIN } from 'utils/features/subdomains/constants';
import {
  getCurrentSubdomain,
  getSubdomain,
} from 'utils/features/subdomains/getSubdomain';
import { getSubdomainHref } from 'utils/features/subdomains/getSubdomainHref';
import { isPreviewApp } from 'utils/isPreviewApp';
import { assignWindowLocation } from 'utils/window';

const CheckRedirectToSubdomain = ({ children }) => {
  const kioskUrl = useSelector((state) => {
    return state.getIn(['kiosk', 'kioskUrl']);
  });

  const currentSubdomain = getCurrentSubdomain();
  const primarySubdomain = useSelector((state) => {
    const subdomains = state
      .getIn(['authenticate', 'company', 'relationships', 'companySubdomains'])
      ?.toJS();
    const primarySubdomain = subdomains?.find(
      (subdomain) => subdomain.attributes.primary,
    );
    return primarySubdomain?.attributes?.subdomain;
  });

  const isAuthenticated = useSelector((state) =>
    state.getIn(['authenticate', 'isAuthenticated']),
  );

  if (kioskUrl) {
    if (isPreviewApp()) {
      const currentSubdomain = getCurrentSubdomain();
      const kioskURLSubdomain = getSubdomain(new URL(kioskUrl).hostname);

      if (currentSubdomain !== kioskURLSubdomain) {
        const newUrl = getSubdomainHref({
          href: window.location.href,
          newSubdomain: kioskURLSubdomain,
          currentSubdomain,
        });

        assignWindowLocation(newUrl);
      }
    } else {
      if (new URL(kioskUrl).origin !== window.location.origin) {
        assignWindowLocation(kioskUrl);
      }
    }
  }

  if (isAuthenticated) {
    if (primarySubdomain && primarySubdomain !== currentSubdomain) {
      const subdomainHref = getSubdomainHref({
        href: window.location.href,
        newSubdomain: primarySubdomain,
        currentSubdomain,
      });
      assignWindowLocation(subdomainHref);
    }

    if (!primarySubdomain && currentSubdomain !== SURVEY_SUBDOMAIN) {
      const subdomainHref = getSubdomainHref({
        href: window.location.href,
        newSubdomain: SURVEY_SUBDOMAIN,
        currentSubdomain,
      });
      assignWindowLocation(subdomainHref);
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

CheckRedirectToSubdomain.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CheckRedirectToSubdomain;
