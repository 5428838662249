import get from 'lodash/get';

import Survey, {
  ConfidentialityDisclaimerRecord,
  RawDataExportRecord,
} from '../records/Survey';

const parseContext = ({ id, attributes = {} } = {}) => ({
  contextId: id,
  ...attributes,
});

export default function survey(state = new Survey(), action) {
  switch (action.type) {
    case 'QUESTION_CHANGED': {
      const { questionId } = action;

      return state.set('currentQuestion', questionId);
    }
    case 'QUESTION_FETCHED': {
      const { question } = action;

      return state.currentQuestion === null
        ? state.set('currentQuestion', question.id)
        : state;
    }
    case 'SURVEY_SUBMIT_START':
      return state.set('isSubmitting', true);

    case 'SURVEY_CONFIRM_SUBMIT':
      return state.set('confirmSubmit', true);

    case 'SURVEY_CANCEL_SUBMIT':
      return state.set('confirmSubmit', false);

    case 'SURVEY_NEXT_START_FETCHED':
      return state.set('nextStart', action.nextStart);

    case 'SURVEY_SUBMIT_COMPLETE':
      return state.merge({
        isSubmitting: false,
        error: null,
        submitted: true,
      });

    case 'SURVEY_SUBMIT_ERROR':
      return state.merge({
        isSubmitting: false,
        error: action.error,
      });

    case 'SURVEY_COVER_INFO_FETCHED':
      return state.merge({
        coverInfo: action.coverInfo,
      });

    case 'SURVEY_RESTORED': {
      const {
        info,
        context,
        surveyBranding,
        coverInfo,
        survey,
        surveyQuestionSets,
      } = action.data;

      return state.merge({
        anonymityLevel: get(survey, 'anonymityLevel'),
        info,
        context,
        surveyBranding,
        coverInfo,
        surveyQuestionSets,
      });
    }

    case 'SURVEY_QUESTION_SETS_UPDATED': {
      return state.merge({
        surveyQuestionSets: state.surveyQuestionSets || action.data,
      });
    }

    case 'SURVEY_DURATION_UPDATED': {
      return state.merge({
        coverInfo: state.coverInfo || action.data,
        info: action.data,
      });
    }

    case 'SURVEY_INFO_FETCHED': {
      const {
        anonymityLevel,
        illustrations,
        firstSurvey,
        brandingImage,
        brandingContext,
        rawDataExport,
        confidentialityDisclaimer,
        resetAvailable,
        responseStatus,
      } = action.data;

      return state.merge({
        anonymityLevel,
        context: parseContext(brandingContext),
        surveyBranding: brandingImage,
        firstSurvey,
        hideIllustrations: !illustrations,
        rawDataExport: new RawDataExportRecord(rawDataExport),
        confidentialityDisclaimer: new ConfidentialityDisclaimerRecord(
          confidentialityDisclaimer,
        ),
        responseStatus,
        resetAvailable,
      });
    }

    case 'RESPONSE_RESETTED': {
      return state.merge({
        responseStatus: 'open',
      });
    }

    case 'SURVEY_SET_PREVIEW':
      return state.set('isPreview', true);

    case 'SURVEY_SET_CONTEXT':
      return state.merge({
        contextId: action.contextId,
        segmentIds: action.segmentIds,
      });

    case 'SURVEY_SET_KIOSK': {
      return state.set('isKiosk', true);
    }
    case 'SURVEY_SET_KIOSK_CODE':
      return state.merge({
        kioskCode: action.kioskCode,
        scheduleCode: action.scheduleCode,
      });
    case 'SURVEY_SET_FIRST_SURVEY':
      return state.set('firstSurvey', true);
    case 'SURVEY_UNSET_FIRST_SURVEY':
      return state.set('firstSurvey', false);
    case 'SURVEY_SKIP_TO_THANK_YOU_PAGE':
      return state.set('isSkipped', true);
    case 'SURVEY_UNSKIP_THANK_YOU_PAGE':
      return state.set('isSkipped', false);
    default:
      return state;
  }
}
