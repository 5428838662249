import React from 'react';

import moment from 'moment';

import {
  NavigationInsightCommentsIcon as CommentsIcon,
  DateAndTimeTimeIcon as ClockIcon,
  NavigationAdministrationAccessControlIcon as LockIcon,
} from '@peakon/bedrock/icons/system';

import styles from './SurveyInfoStats.scss';
import { useT } from 'features/i18n/useT';

type Props = {
  info: {
    estimated: number;
    seconds: number;
  };
};

export default function SurveyInfoStats({ info }: Props) {
  const t = useT();
  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <CommentsIcon />
        <div>
          {t('survey__welcome__approx_questions', {
            replace: {
              count: info.estimated,
            },
          })}
        </div>
      </div>

      <div className={styles.item}>
        <ClockIcon />
        <div>
          {t('survey_welcome_time_icu', {
            replace: {
              numOfMinutes: Math.round(
                moment
                  .duration({
                    seconds: info.seconds,
                  })
                  .asMinutes(),
              ),
            },
          })}
        </div>
      </div>

      <div className={styles.item}>
        <LockIcon />
        <div>{t('survey__welcome__anonymous__confidential')}</div>
      </div>
    </div>
  );
}
