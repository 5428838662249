import Immutable from 'immutable';

const initialState = Immutable.fromJS({
  data: null,
  show: false,
});

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case 'NOTIFICATION_SHOW':
      return state.set('data', Immutable.fromJS(action.data)).set('show', true);

    case 'NOTIFICATION_HIDE':
      return state.set('data', null).set('show', false);

    default:
      return state;
  }
}
