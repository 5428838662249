import React, { Component } from 'react';

import { SurveyInfo, Header, Footer } from 'components';
import { List, OrderedSet } from 'immutable';
import each from 'lodash/each';
import get from 'lodash/get';
import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { withTranslation } from 'react-i18next';
import noop from 'lodash/noop';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import storejs from 'store';

import { LinkButton } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/components';

import * as AuthenticateActions from 'actions/authenticate';
import CustomConfidentialityDisclaimer from 'components/CustomConfidentialityDisclaimer';
import RawDataExportDisclaimer from 'components/RawDataExportDisclaimer';
import { SURVEY_OPENED, START_SURVEY } from 'constants/mixpanelEvents';
import * as stateUtil from 'lib/stateUtil';
import { analytics } from 'utils/features/analytics/analytics';

import styles from './Home.scss';

const keyboardShortcuts = {
  next: ['shift+enter', 'down'],
};

export class Home extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();

    this.state = {
      isConfidentialExpanded: false,
    };
  }

  componentDidMount() {
    const { isMobile, rawDataExport } = this.props;

    if (this.buttonRef.current) {
      // react-router 3.2.1 has no support for passing an innerRef to Link
      // so this wraps the component
      const button = this.buttonRef.current.firstChild;

      button.focus();
    }

    if (!isMobile) {
      this.bindKeyboardShortcuts();
    }

    if (
      get(rawDataExport, 'enabled') &&
      get(rawDataExport, 'hasSeenRawDataExportMessage') === false
    ) {
      storejs.set('RAW_DATA_EXPORT_MESSAGE_SEEN', true);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { surveyQuestionSets, isPreview } = nextProps;

    if (!isPreview) {
      surveyQuestionSets
        ? // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
          analytics.track(SURVEY_OPENED, {
            category_groups: surveyQuestionSets,
          })
        : // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
          analytics.track(SURVEY_OPENED);
    }

    return null;
  }

  componentDidUpdate() {
    if (!this.didBlur && this.buttonRef.current) {
      this.buttonRef.current.firstChild.focus();
    }
  }

  onBlur = () => {
    this.didBlur = true;
  };

  componentWillUnmount() {
    if (!this.props.isMobile) {
      each(keyboardShortcuts, (value) => Mousetrap.unbind(value));
    }
  }

  bindKeyboardShortcuts() {
    Mousetrap.bind(keyboardShortcuts.next, (e) => {
      e.preventDefault();
      this.goToNextQuestion();
    });
  }

  goToNextQuestion() {
    const { history } = this.props;
    const nextUrl = this.getNextUrl();

    if (nextUrl) {
      history.push(nextUrl);
    }
  }

  getNextUrl() {
    const { attributes, match, questions } = this.props;

    if (attributes && !attributes.isEmpty()) {
      return `/survey/answer/${match.params.token}/attributes`;
    }

    const nextQuestion = questions.first();
    if (nextQuestion) {
      return `/survey/answer/${match.params.token}/question/${nextQuestion.id}`;
    }
  }

  renderBranding() {
    const logo = get(this.props, 'context.logo');
    const surveyBranding = get(this.props, 'surveyBranding');

    if (logo) {
      return surveyBranding ? (
        <div className={styles.branding}>
          <div className={styles.surveyBranding}>
            <img
              src={`${surveyBranding}?fit=max&w=300&h=168&dpr=2`}
              className={styles.surveyBrandingImage}
            />
          </div>
        </div>
      ) : (
        <div className={styles.logo}>
          <img
            src={`${logo}?fit=max&w=250&h=250&dpr=2`}
            alt=""
            className={styles.logoImage}
            data-test-id="home-logo"
          />
        </div>
      );
    } else {
      return surveyBranding ? (
        <div className={styles.branding}>
          <div className={styles.surveyBranding}>
            <img
              src={`${surveyBranding}?fit=max&w=300&h=168&dpr=2`}
              className={styles.surveyBrandingImage}
            />
          </div>
        </div>
      ) : null;
    }
  }

  renderContent() {
    const { context, questions, isMobile, t, surveyQuestionSets, isPreview } =
      this.props;

    if (questions.size > 0) {
      const trackStartSurvey = () =>
        surveyQuestionSets
          ? // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
            analytics.track(START_SURVEY, {
              category_groups: surveyQuestionSets,
            })
          : // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
            analytics.track(START_SURVEY);
      return (
        <div>
          <h1 className={styles.title}>
            {t('survey__welcome__headline', {
              company: context.name,
            })}
          </h1>
          {this.renderInfo()}
          <div ref={this.buttonRef} className={styles.buttonContainer}>
            <LinkButton
              variant="primary"
              as={Link}
              size={isMobile ? 'large' : 'medium'}
              data-test-id="survey-start"
              to={this.getNextUrl()}
              onClick={!isPreview ? trackStartSurvey : noop}
              onBlur={this.onBlur}
            >
              {t('survey__navigation__start')}
            </LinkButton>
          </div>
          <div className={styles.disclaimers}>
            <RawDataExportDisclaimer />
            <CustomConfidentialityDisclaimer />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderInfo() {
    const { surveyInfo } = this.props;

    if (!surveyInfo) {
      return null;
    }

    return <SurveyInfo info={surveyInfo} />;
  }

  render() {
    if (!this.props.context || this.props.isLoading) {
      return (
        <div className={styles.loading}>
          <Spinner />
        </div>
      );
    } else {
      return (
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.header} role="navigation">
              <Header
                isHome
                isMobile={this.props.isMobile}
                hasSurveyBranding={Boolean(this.props.surveyBranding)}
                context={this.props.context}
                isKiosk={this.props.isKiosk}
                scheduleCode={this.props.scheduleCode}
                kioskCode={this.props.kioskCode}
                kioskPublic={this.props.kioskPublic}
              />
            </div>
            <div className={styles.wrapper} role="main">
              {this.renderBranding()}
              <div className={styles.content}>{this.renderContent()}</div>
            </div>
            <div className={styles.footer} role="contentinfo">
              <Footer />
            </div>
          </div>
        </div>
      );
    }
  }
}

Home.propTypes = {
  attributes: PropTypes.instanceOf(List),
  context: PropTypes.object,
  questions: PropTypes.instanceOf(OrderedSet),
  match: PropTypes.object,
  surveyInfo: PropTypes.object,
  surveyQuestionSets: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isKiosk: PropTypes.bool,
  kioskCode: PropTypes.string,
  kioskPublic: PropTypes.bool,
  scheduleCode: PropTypes.string,
  surveyBranding: PropTypes.string,
  rawDataExport: PropTypes.object,
  isPreview: PropTypes.bool,
  t: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    attributes: state.getIn(['attributes', 'data']),
    context: stateUtil.toJS(['survey', 'context'], state),
    surveyInfo: stateUtil.toJS(['survey', 'coverInfo'], state),
    surveyQuestionSets: stateUtil.toJS(['survey', 'surveyQuestionSets'], state),
    isPreview: state.getIn(['survey', 'isPreview']),
    questions: state.get('questions'),
    isLoading: state.getIn(['questionState', 'isLoading']),
    isMobile: state.getIn(['ui', 'isMobile']),
    isKiosk: state.getIn(['survey', 'isKiosk']),
    kioskCode: state.getIn(['survey', 'kioskCode']),
    scheduleCode: state.getIn(['survey', 'scheduleCode']),
    surveyBranding: state.getIn(['survey', 'surveyBranding']),
    kioskPublic: state.getIn(['kiosk', 'isPublic']),
    rawDataExport: state.getIn(['survey', 'rawDataExport']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(AuthenticateActions, dispatch),
  };
};

export const HomeContainer = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Home),
);
