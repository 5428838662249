import React from 'react';

import { Spinner } from '@peakon/components';

import styles from './ValueCard.scss';

const Loading = () => (
  <div className={styles.root}>
    <div>
      <div>
        <Spinner />
      </div>
    </div>
  </div>
);

export default Loading;
