import React from 'react';

import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import styles from './styles.scss';
import transitions from './transitions.scss';
import { parseProgress } from './util';
import Clock from '../Clock';

const TimeLeft = ({ answered, estimated, seconds }) => {
  const { t } = useTranslation();
  const { digits, progress } = parseProgress({
    answered,
    estimated,
    seconds,
  });

  return (
    <div className={styles.root}>
      <div className={styles.minutes}>
        <Clock progress={progress}>
          <div className={styles.estimated}>
            <div className={styles.tilde}>~</div>
            <TransitionGroup>
              {digits.map((digit, index) => (
                <CSSTransition
                  key={index}
                  timeout={0}
                  classNames={transitions}
                  className={styles.transitionGroup}
                >
                  <div key={digit} className={styles.count}>
                    {digit}
                  </div>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
          <div className={styles.label}>
            {t('survey__minutes__abbreviation')}
          </div>
        </Clock>
      </div>
    </div>
  );
};

TimeLeft.propTypes = {
  answered: PropTypes.number,
  estimated: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
};

export default TimeLeft;
