import uniqBy from 'lodash/uniqBy';

const featureFactory = (sessionFeatures = []) => {
  return uniqBy(
    sessionFeatures.map((feature) => {
      return {
        key: feature,
        name: feature,
        active: true, // features from api is assumed active
      };
    }),
    'key',
  );
};

export default featureFactory;
