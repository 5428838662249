import moment from 'moment';
import { employeeLocales } from './localesHelper';

const loadMomentLocale = (locale) =>
  locale.moment !== 'en' && import(`moment/locale/${locale.moment}`);

const changeAsyncLanguages = async (i18n, language) => {
  const locale = employeeLocales.resolve(language);

  await loadMomentLocale(locale);

  moment.locale(locale.moment);
};

export default changeAsyncLanguages;
