import React from 'react';

// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';

import { UnstyledButton } from '@peakon/bedrock/react/button';

import * as nativeUtil from 'lib/nativeUtil';

import styles from './CloseButton.scss';

export default function CloseButton() {
  const { t } = useTranslation();

  const close = () => {
    nativeUtil.emit(nativeUtil.events.CLOSE);
  };

  return (
    <div className={styles.container}>
      <UnstyledButton
        className={styles.button}
        onClick={close}
        data-test-id="native-close-button"
        accessibleName={t('survey__close')}
        insetFocus
      >
        {t('survey__close')}
      </UnstyledButton>
    </div>
  );
}
