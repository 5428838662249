export const showErrorNotification =
  ({ message, timeout = 10000 } = {}) =>
  (dispatch) => {
    dispatch(
      showNotification({
        type: 'error',
        message,
      }),
    );

    if (timeout) {
      setTimeout(() => dispatch(hideNotification()), timeout);
    }
  };

export function showNotification(data) {
  return {
    type: 'NOTIFICATION_SHOW',
    data,
  };
}

export function hideNotification() {
  return {
    type: 'NOTIFICATION_HIDE',
  };
}
