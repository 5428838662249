import merge from 'lodash/merge';

import ErrorReporter from '@peakon/error-reporter';

import { getBuffer } from './lib/middlewares';

// Unset the default window error as Rollbar should take that job now
window.onerror = null;

const errorReporter = new ErrorReporter({
  accessToken: ENV.rollbar ? ENV.rollbar.client : '',
  environment: ENV.environment,
  version: ENV.version,
  transform: (payload) => {
    merge(payload, {
      data: {
        custom: {
          previousActions: getBuffer(),
        },
      },
    });
  },
});

window.errorReporter = errorReporter;

export default errorReporter;
