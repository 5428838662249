import get from 'lodash/get';
import HttpError from 'standard-http-error';

import parseAnswers from 'lib/parseAnswers';

import { localesLoaded } from './locale';
import { updateSurveyDuration, updateSurveyQuestionSets } from './survey';
import api from '../api';

export function questionStart() {
  return {
    type: 'QUESTION_START',
  };
}

export function questionError(error) {
  return {
    type: 'QUESTION_ERROR',
    error,
  };
}

export function questionFetched(question) {
  return {
    type: 'QUESTION_FETCHED',
    question,
  };
}

export function questionChanged(questionId) {
  return {
    type: 'QUESTION_CHANGED',
    questionId,
  };
}

export function questionCommented(questionId, comment) {
  return {
    type: 'QUESTION_COMMENTED',
    questionId,
    comment,
  };
}

export function questionSkipStart() {
  return {
    type: 'QUESTION_SKIP_START',
  };
}

export function questionSkipCancel(questionId) {
  return {
    type: 'QUESTION_SKIP_CANCEL',
    questionId,
  };
}

export function questionSkipped(questionId, reason) {
  return {
    type: 'QUESTION_SKIPPED',
    questionId,
    reason,
  };
}

export function questionsEnded() {
  return {
    type: 'QUESTIONS_ENDED',
  };
}

export function questionLoaded() {
  return {
    type: 'QUESTION_LOADED',
  };
}

export const fetchQuestions = (locale) => async (dispatch, getState) => {
  const state = getState();
  const answers = state.get('answers');
  const isPreview = state.getIn(['survey', 'isPreview']);
  const contextId = state.getIn(['survey', 'contextId']);
  const responseId = state.getIn(['authenticate', 'response', 'responseId']);
  const segmentIds = state.getIn(['survey', 'segmentIds']);

  let questions;

  try {
    if (isPreview) {
      questions = await api.response.getPreviewQuestions(
        contextId,
        {
          segmentIds,
          include: 'value',
        },
        parseAnswers(answers, { excludeUnanswered: true }),
        locale,
      );
    } else {
      questions = await api.response.getQuestions(
        responseId,
        {
          include: 'value,category',
        },
        parseAnswers(answers, { excludeUnanswered: true }),
        locale,
      );
    }
  } catch (err) {
    if (!(err instanceof HttpError)) {
      throw err;
    }
  }

  if (questions) {
    const answeredQuestionsIds = answers.valueSeq().map((a) => a.id);

    return dispatch({
      type: 'QUESTIONS_REFETCHED',
      data: {
        questions: Object.assign({}, questions, {
          data: questions.data.filter((q) =>
            answeredQuestionsIds.includes(q.id),
          ),
        }),
      },
    });
  }
};

export function fetchNextQuestion(update = false) {
  return async function (dispatch, getState) {
    const state = getState();

    if (state.getIn(['questionState', 'isLoading'])) {
      throw new Error('Question fetching already in progress.');
    }

    const responseId = state.getIn(['authenticate', 'response', 'responseId']);
    const answers = state.getIn(['answers']);
    const locale = state.getIn(['locale', 'locale']);
    const isPreview = state.getIn(['survey', 'isPreview']);
    const contextId = state.getIn(['survey', 'contextId']);
    const segmentIds = state.getIn(['survey', 'segmentIds']);

    let question;

    dispatch(questionStart());

    try {
      if (isPreview) {
        question = await api.response.getNextPreviewQuestion(
          contextId,
          {
            segmentIds,
            include: 'value',
          },
          parseAnswers(answers, { excludeUnanswered: true }),
          locale,
        );
      } else {
        question = await api.response.getNextQuestion(
          responseId,
          {
            include: 'value,category',
          },
          parseAnswers(answers, { excludeUnanswered: true }),
          locale,
        );
      }
    } catch (err) {
      dispatch(questionError(err));

      if (!(err instanceof HttpError)) {
        throw err;
      }
    }

    if (update) {
      dispatch(questionLoaded());
    } else {
      if (question) {
        dispatch(questionFetched(question.data));
      } else if (question === null) {
        dispatch(questionsEnded());
      }
    }

    // With every response, counts might change as they
    // depend on outlier answers
    const counts = get(question, 'meta.counts');
    const categoryGroup = get(question, 'meta.categoryGroups');

    if (categoryGroup) {
      dispatch(updateSurveyQuestionSets(categoryGroup));
    }

    if (counts) {
      dispatch(updateSurveyDuration(counts));
    }

    // available locales for the survey, [] means all of them
    const locales = get(question, 'meta.locales', []);
    const questionLocale = get(question, 'meta.locale');
    dispatch(
      localesLoaded(locales, {
        questionLocale,
      }),
    );

    return question;
  };
}

export const questionAnswered = (answer) => ({
  type: 'QUESTION_ANSWERED',
  data: { answer },
});

export function questionScaleSet(questionId, scale) {
  return {
    type: 'QUESTION_SCALE_SET',
    questionId,
    scale,
  };
}

export function questionScaleUnset(questionId) {
  return {
    type: 'QUESTION_SCALE_UNSET',
    questionId,
  };
}

export function toggleComment(questionId) {
  return {
    type: 'QUESTION_TOGGLE_COMMENT',
    questionId,
  };
}
