import React from 'react';

import styles from './SurveyInfo.scss';
import SurveyInfoStats from './SurveyInfoStats';
import { useT } from 'features/i18n/useT';

const getFrequencyTranslation = (
  frequency: number,
  // Todo: import `t` directly from shared translation function once it is available (https://jira2.workday.com/browse/PEAKONLEAD-3491)
  t: ReturnType<typeof useT>,
) => {
  switch (frequency) {
    case 0:
      return t('survey__welcome__your_survey__manual', {
        components: {
          emphasis: <strong />,
        },
      });
    case 1:
      return t('survey__welcome__your_survey__weekly_v2', {
        components: {
          emphasis: <strong />,
        },
      });
    case 2:
      return t('survey__welcome__your_survey__bi_weekly_v2', {
        components: {
          emphasis: <strong />,
        },
      });
    case 4:
      return t('survey__welcome__your_survey__monthly');
    default:
      return t('survey__welcome__your_survey__custom_v2', {
        components: {
          emphasis: <strong />,
        },
      });
  }
};

type Props = {
  info: {
    frequency: number;
    estimated: number;
    seconds: number;
  };
};

export default function SurveyInfo({ info }: Props) {
  const t = useT();

  return (
    <div className={styles.root} data-test-id="survey-info-wrapper">
      <div
        className={styles.frequency}
        data-test-id="survey-info-frequency-wrapper"
      >
        {getFrequencyTranslation(info.frequency, t)}
      </div>
      <SurveyInfoStats info={info} />
    </div>
  );
}
