import bean from 'bean';

export const events = {
  AUTHENTICATED: 'native-authenticated',
  CLOSE: 'native-close',
  SUBMIT: 'native-submit',
  ERROR: 'native-error',
};

export function emit(eventName) {
  const parentWindow = window.parent || window.top;

  if (window.peakon && window.peakon.native) {
    bean.fire(window.document, eventName);
  } else if (parentWindow) {
    try {
      parentWindow.postMessage(
        {
          eventName,
        },
        ENV.host,
      );
    } catch (err) {
      // noop
    }
  }
}

export function getLocale() {
  return window.peakon ? window.peakon.locale : undefined;
}
