import { List } from 'immutable';
import isPlainObject from 'lodash/isPlainObject';

let logBuffer = new List();

export const getBuffer = () => logBuffer.toArray();

export const logMiddleware =
  (maxActions = 20) =>
  (store) =>
  (next) =>
  (action) => {
    logBuffer = logBuffer.push(action.type).takeLast(maxActions);

    return next(action);
  };

// Why Promise? https://stackoverflow.com/a/41813156
export const errorMiddleware =
  (errorHandler) => (store) => (next) => (action) => {
    if (isPlainObject(action)) {
      try {
        return next(action);
      } catch (error) {
        errorHandler(error);
        throw error;
      }
    } else {
      return Promise.resolve(next(action)).catch((error) => {
        errorHandler(error);
        throw error;
      });
    }
  };
