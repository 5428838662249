import React, { useState } from 'react';

import { SystemBanner } from 'components';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';

import { EditCloseIcon as CloseIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';

import { getTranslationFragments } from 'utils/preRender';

import styles from './Survey.scss';

function getBannerContent(isSkipped, handlePreviewThankYouPage, t) {
  let fragments;

  if (isSkipped || window.location.pathname.includes('/thankyou')) {
    fragments = getTranslationFragments(
      t,
      `survey__submit__preview__v2__skipped`,
      {
        emphasis: (value) => <strong key="emphasis">{value}</strong>,
      },
    );
  } else {
    fragments = getTranslationFragments(
      t,
      'survey__submit__preview__v2__skip',
      {
        emphasis: (value) => {
          return <strong key="emphasis">{value}</strong>;
        },
        link: (value) => {
          return (
            <a
              key="link"
              href="#"
              className="peak-link"
              onClick={handlePreviewThankYouPage}
            >
              {value}
            </a>
          );
        },
      },
    );
  }
  return fragments;
}

function PreviewBanner({ isSkipped, handlePreviewThankYouPage }) {
  const [closed, setClosed] = useState(false);
  const { t } = useTranslation();

  if (closed) {
    return null;
  }

  return (
    <SystemBanner position="bottom">
      {getBannerContent(isSkipped, handlePreviewThankYouPage, t)}
      <UnstyledButton
        className={styles.previewClose}
        onClick={() => setClosed(true)}
        accessibleName={t('common_close')}
      >
        <CloseIcon />
      </UnstyledButton>
    </SystemBanner>
  );
}

PreviewBanner.propTypes = {
  handlePreviewThankYouPage: PropTypes.func,
  isSkipped: PropTypes.bool,
};

export default PreviewBanner;
