import React from 'react';

import PropTypes from 'prop-types';

import {
  ScoresChangeArrowRisingIcon as ScoreIncrease,
  ScoresChangeArrowFallingIcon as ScoreDecrease,
} from '@peakon/bedrock/icons/graphic';

import Text from 'components/Text';

import Card from './';

function ScoreChangeCard({ sign, loginHref, isRenderedInIframe }) {
  const hasIncreased = sign !== -1;

  return (
    <Card
      icon={hasIncreased ? ScoreIncrease : ScoreDecrease}
      title={
        hasIncreased ? (
          <Text>employee_dashboard__v2__satisfied_increase</Text>
        ) : (
          <Text>employee_dashboard__v2__satisfied_decrease</Text>
        )
      }
      description={
        hasIncreased ? (
          <Text>employee_dashboard__v2__satisfied_increase_desc</Text>
        ) : (
          <Text>employee_dashboard__v2__satisfied_decrease_desc</Text>
        )
      }
      action={
        !isRenderedInIframe && (
          <a
            className="peak-btn peak-btn-secondary peak-btn--sm"
            href={loginHref}
          >
            <Text>employee_dashboard__v2__learn_more</Text>
          </a>
        )
      }
      data-test-id="score-change-card"
    />
  );
}

ScoreChangeCard.propTypes = {
  sign: PropTypes.number,
  loginHref: PropTypes.string,
  isRenderedInIframe: PropTypes.bool,
};

export default ScoreChangeCard;
