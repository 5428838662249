import { Record } from 'immutable';

export class RawDataExportRecord extends Record({
  enabled: null,
  message: null,
  hasSeenRawDataExportMessage: null,
}) {}

export class ConfidentialityDisclaimerRecord extends Record({
  enabled: null,
  message: null,
}) {}

export default class Survey extends Record({
  anonymityLevel: undefined,
  confirmSubmit: false,
  context: null,
  contextId: null,
  coverInfo: null,
  surveyQuestionSets: null,
  currentQuestion: null,
  error: null,
  firstSurvey: false,
  hideIllustrations: false,
  info: null,
  isKiosk: false,
  isPreview: false,
  isSubmitting: false,
  kioskCode: null,
  nextStart: null,
  resetAvailable: false,
  responseStatus: null,
  scheduleCode: null,
  segmentIds: null,
  submitted: false,
  summary: null,
  surveyBranding: null,
  isSkipped: false,
  rawDataExport: new RawDataExportRecord(),
  confidentialityDisclaimer: new ConfidentialityDisclaimerRecord(),
}) {}
