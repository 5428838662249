import moment from 'moment';

import { findLanguage } from '../features/i18n/languageUtil';
import { getLocale as getNativeLocale } from 'lib/nativeUtil';

import { readCode } from './kiosk';
import { fetchQuestions } from './questions';
import { getUserAttributesValuesTranslations } from './response';
import { getSurveyInfo } from './surveyInfo';
import { getCustomConsentStatus } from '../actions/customConsent';
import api from '../api';
import i18n, { setRtl } from '../features/i18n/i18n';
import { responseIdSelector } from '../selectors/ResponseIdSelectors';

export const initLocale = (locale) => (dispatch, getState) => {
  const nativeLocale =
    getState().getIn(['ui', 'isNative']) && getNativeLocale();

  // if there is a native locale we need to enforce that due
  // to App Store policies (we don't display the language picker on iOS)
  if (nativeLocale) {
    dispatch(forceLocale(nativeLocale));
  } else {
    // this is also when the browser language is detected from i18n
    // note that this can either be the actual browser language
    // or the language stored for i18next (i18nextLng on LocalStorage)
    dispatch(setBrowserLocale(locale));

    dispatch(setLocale(locale));
  }
};

export const accountLocaleFetched = (accountLocale) => (dispatch, getState) => {
  const locale = getState().getIn(['locale', 'locale']);

  if (accountLocale !== locale) {
    dispatch(setLocale(accountLocale));
  }
};

export const setBrowserLocale = (browserLocale) => ({
  type: 'BROWSER_LOCALE_SET',
  browserLocale,
});

export const localeRestored = (storedLocale) => (dispatch, getState) => {
  const locale = getState().getIn(['locale', 'locale']);

  if (storedLocale !== locale) {
    dispatch(setLocale(storedLocale));
  }
};

export const localesLoaded =
  (locales, { questionLocale, refetch = true } = {}) =>
  (dispatch, getState) => {
    const state = getState();

    const browserLocale = state.getIn(['locale', 'browserLocale']);
    const forcedLocale = state.getIn(['locale', 'forcedLocale']);
    const locale = state.getIn(['locale', 'locale']);

    const localeOverwrite = locales.length === 1 && locales[0];

    // admin forced locale
    if (localeOverwrite) {
      if (!forcedLocale) {
        dispatch(forceLocale(localeOverwrite));

        // if it's also different, then refetch questions
        if (refetch && localeOverwrite !== locale && locale) {
          dispatch(fetchQuestions(localeOverwrite));
        }
      } else if (localeOverwrite !== locale) {
        dispatch(forceLocale(localeOverwrite));

        if (refetch) {
          dispatch(fetchQuestions(localeOverwrite));
        }
      }
      // admin limited available locales
    } else if (locales.length > 1) {
      // check if current locale is valid
      if (!locale || !locales.includes(locale)) {
        const newLocale =
          questionLocale ||
          (browserLocale && locales.includes(browserLocale)
            ? browserLocale
            : locales[0]);
        dispatch(setLocale(newLocale));
      }

      dispatch(setAvailableLocales(locales));
    }
  };

export const setAvailableLocales = (availableLocales) => ({
  type: 'AVAILABLE_LOCALES_SET',
  availableLocales,
});

export const forceLocale = (locale) => (dispatch) => {
  dispatch(localeForced());
  dispatch(setLocale(locale));
};

export const localeForced = () => ({
  type: 'LOCALE_FORCED',
});

export const changeLocale = (locale) => async (dispatch, getState) => {
  const state = getState();

  const isAuthenticated = state.getIn(['authenticate', 'isAuthenticated']);
  const isPreview = state.getIn(['survey', 'isPreview']);
  const isKiosk = state.getIn(['survey', 'isKiosk']);
  const kioskMode = state.getIn(['kiosk', 'mode']);
  const attributes = state.getIn(['attributes', 'data']);

  dispatch(setLocale(locale));

  if (isAuthenticated) {
    dispatch(fetchQuestions(locale));

    if (!isPreview && !isKiosk) {
      await dispatch(persistLocale(locale));
    }

    // This is required in order to get the translated `attributeMessage` and attribute
    // along with the confidentiality text
    dispatch(
      getSurveyInfo(state.getIn(['authenticate', 'response', 'responseId'])),
    );

    if (attributes && !attributes.isEmpty()) {
      // fetch translated attribute values
      if (!isPreview) {
        dispatch(getUserAttributesValuesTranslations());
      }
      dispatch(getCustomConsentStatus(responseIdSelector(state)));
    }
  } else {
    if (
      ['ephemeral', 'employeeCode', 'roundCode', 'employeeLogin'].includes(
        kioskMode,
      )
    ) {
      const scheduleCode = state.getIn(['kiosk', 'scheduleCode']);
      if (scheduleCode) {
        dispatch(readCode(scheduleCode));
      }
    }
  }
};

export const setLocale = (locale) => (dispatch) => {
  const language = findLanguage(locale);

  api.setLocale(language.id);

  moment.locale(language.moment);

  i18n.changeLanguage(language.id);

  // side-effect
  setRtl(language.rtl);

  dispatch(localeChanged(language.id));
};

export const localeChanged = (locale) => ({
  type: 'LOCALE_CHANGED',
  locale,
});

export const persistLocale = (locale) => async (dispatch, getState) => {
  const account = getState().getIn(['authenticate', 'account']).toJS();

  try {
    await api.account.update('me', null, {
      data: Object.assign({}, account, {
        attributes: {
          locale,
        },
      }),
    });

    dispatch({ type: 'PERSIST_LOCALE_SUCCESS', locale });
  } catch (error) {
    dispatch({ type: 'PERSIST_LOCALE_ERROR' });
  }
};
