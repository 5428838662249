export function mobileDetected(isMobile) {
  return {
    type: 'UI_MOBILE_DETECTED',
    isMobile,
  };
}

export function nativeDetected(isNative) {
  return {
    type: 'UI_NATIVE_DETECTED',
    isNative,
  };
}

export function setIsRenderedInIFrame(isRenderedInIframe) {
  return {
    type: 'UI_IFRAME_DETECTED',
    isRenderedInIframe,
  };
}

export function inputFocused() {
  return {
    type: 'UI_INPUT_FOCUSED',
  };
}

export function inputBlurred() {
  return {
    type: 'UI_INPUT_BLURRED',
  };
}
