import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { withTranslation } from 'react-i18next';

import { NavigationBasicNavigationArrowRightIcon as ArrowIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';

import styles from './KioskCodeInput.scss';

const KioskCodeInput = ({ error, onSubmit, isSubmitting, t, mode }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    const validLength = mode === 'employeeCode' ? 12 : 10;

    setHasChanges(true);
    setIsValid(e.target.value.length === validLength);
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    if (isValid) {
      setHasChanges(false);

      if (onSubmit) {
        onSubmit(value);
      }
    }
  };

  const renderError = () => {
    if (error && !hasChanges) {
      const errorPrefix = t('error_message__error_warning_prefix');

      return (
        <div
          className={cx('is-negative', styles.errorMessage)}
          id="kiosk-input-error-message"
          data-test-id="kiosk-input-error-message"
          role="alert"
        >
          {`${errorPrefix} ${error.message}`}
        </div>
      );
    }

    return null;
  };

  const isEmployeeCode = mode === 'employeeCode';

  return (
    <div className={styles.root}>
      <form
        method="POST"
        onSubmit={handleSubmit}
        className={cx(styles.form, {
          [styles.error]: error && !hasChanges,
          [styles.valid]: isValid,
          [styles.employeeCode]: isEmployeeCode,
        })}
      >
        <label htmlFor="kiosk-code-input" className={styles.formTitle}>
          {t('survey__kiosk__enter_code')}
        </label>
        <div className={cx('peak-form-text', styles.inputContainer)}>
          <input
            id="kiosk-code-input"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            autoFocus
            className={styles.input}
            disabled={isSubmitting}
            maxLength={isEmployeeCode ? 12 : 10}
            onChange={handleChange}
            spellCheck="false"
            type="text"
            value={value}
            data-test-id="kiosk-code-input"
            aria-invalid={Boolean(error)}
            aria-describedby="kiosk-input-error-message"
          />
        </div>
        <UnstyledButton
          type="submit"
          accessibleName={t('survey__navigation__start')}
          className={styles.button}
          disabled={isSubmitting || (error && !hasChanges)}
          data-test-id="login-btn"
        >
          <ArrowIcon aria-hidden="true" className={styles.arrow} />
        </UnstyledButton>
      </form>
      {renderError()}
    </div>
  );
};

KioskCodeInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  t: PropTypes.func,
  mode: PropTypes.oneOf(['roundCode', 'employeeLogin', 'employeeCode']),
};

export default withTranslation()(KioskCodeInput);
