import React, { Fragment, useState } from 'react';

import removeMarkdown from 'markdown-to-text';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Button } from '@peakon/components';

import styles from './styles.scss';
import { MarkdownWrapper } from '../MarkdownWrapper/MarkdownWrapper';

const kioskModeSelector = (state) => state.getIn(['kiosk', 'mode']);
const kioskConfidentialityDisclaimerSelector = (state) =>
  state.getIn(['kiosk', 'confidentialityDisclaimer']);
const surveyConfidentialityDisclaimerSelector = (state) =>
  state.getIn(['survey', 'confidentialityDisclaimer']);

const getConfidentialityDisclaimer = createSelector(
  [
    kioskModeSelector,
    kioskConfidentialityDisclaimerSelector,
    surveyConfidentialityDisclaimerSelector,
  ],
  (
    kioskMode,
    kioskConfidentialityDisclaimer,
    surveyConfidentialityDisclaimer,
  ) => {
    return kioskMode
      ? kioskConfidentialityDisclaimer
      : surveyConfidentialityDisclaimer;
  },
);

const CustomConfidentialityDisclaimer = () => {
  const { t } = useTranslation();
  // NOTE: the html-ellipsis library will remove one character from the limit when appending the ellipsis
  const CHARACTER_LIMIT = 301;
  const ALLOWED_MARKDOWN_TAGS = ['a', 'strong', 'em'];
  const [isExpanded, setExpanded] = useState(false);
  const confidentialityDisclaimer = useSelector(getConfidentialityDisclaimer);

  const isConfidentialityDisclaimerEnabled =
    confidentialityDisclaimer && confidentialityDisclaimer.enabled;

  if (!isConfidentialityDisclaimerEnabled) {
    return null;
  }

  const confidentialityDisclaimerMessage = confidentialityDisclaimer.message
    ? confidentialityDisclaimer.message
    : t('survey__data_privacy_text__data_export__confidential');

  return (
    <div
      className={styles.customConfidentialityContainer}
      data-test-id="custom-confidentiality-disclaimer-text"
    >
      <div>
        {removeMarkdown(confidentialityDisclaimerMessage).length <=
          CHARACTER_LIMIT || isExpanded ? (
          <MarkdownWrapper
            source={confidentialityDisclaimerMessage}
            allowedTags={ALLOWED_MARKDOWN_TAGS}
            openLinksInNewTab
          />
        ) : (
          <Fragment>
            <MarkdownWrapper
              source={confidentialityDisclaimerMessage}
              allowedTags={ALLOWED_MARKDOWN_TAGS}
              openLinksInNewTab
              truncate={CHARACTER_LIMIT}
            />
            <Button
              type="tertiary"
              onClick={() => setExpanded(true)}
              testId="custom-confidentiality-disclaimer-expand"
            >
              {t('confidential__expand_disclaimer')}
            </Button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default CustomConfidentialityDisclaimer;
