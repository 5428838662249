import React, { useState, useEffect, useRef } from 'react';

import get from 'lodash/get';
import classnames from 'classnames';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { unauthenticate } from 'actions/authenticate';

import styles from './KioskHeader.scss';
import KioskStopOverlay from '../KioskStopOverlay';

const TIMEOUT =
  get(ENV, 'timeouts.kioskPublicShowStopOverlaySeconds.web') || 5 * 60;
const MOBILE_TIMEOUT =
  get(ENV, 'timeouts.kioskPublicShowStopOverlaySeconds.mobile') || 15 * 60;

export const KioskHeader = ({
  actions,
  isHome,
  isMobile,
  isPublic,
  kioskCode,
  scheduleCode,
}) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const { t } = useTranslation();
  const intervalRef = useRef();

  const maxTime = isMobile ? MOBILE_TIMEOUT : TIMEOUT;
  const resetElapsedTime = () => setElapsedTime(0);

  const onStop = async () => {
    await actions.unauthenticate();
    window.location.assign(`/survey/kiosk/${scheduleCode}`);
  };

  const onContinue = () => {
    setIsOverlayVisible(false);
  };

  if (elapsedTime >= maxTime) {
    resetElapsedTime();
    setIsOverlayVisible(true);
  }

  useEffect(() => {
    if (isPublic) {
      intervalRef.current = setInterval(() => {
        setElapsedTime((value) => value + 1);
      }, 1000);

      window.addEventListener('click', resetElapsedTime, false);
      window.addEventListener('scroll', resetElapsedTime, false);
      window.addEventListener('mousemove', resetElapsedTime, false);
      window.addEventListener('touchstart', resetElapsedTime, false);
      window.addEventListener('keydown', resetElapsedTime, false);
    }

    return () => {
      if (isPublic) {
        clearInterval(intervalRef.current);

        window.removeEventListener('click', resetElapsedTime);
        window.removeEventListener('scroll', resetElapsedTime);
        window.removeEventListener('mousemove', resetElapsedTime);
        window.removeEventListener('touchstart', resetElapsedTime);
        window.removeEventListener('keydown', resetElapsedTime);
      }
    };
  }, [isPublic]);

  return (
    <div
      className={classnames(styles.info, {
        [styles.fixed]: !isHome,
      })}
    >
      {kioskCode && (
        <div className={styles.code} data-test-id="kiosk-info-code">
          {kioskCode}
        </div>
      )}

      <button
        className="peak-btn peak-btn-warning peak-btn--sm"
        onClick={() => setIsOverlayVisible(true)}
        data-test-id="kiosk-exit-link"
      >
        {t('survey__stop_survey')}
      </button>

      {isOverlayVisible && (
        <KioskStopOverlay onStop={onStop} onContinue={onContinue} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ unauthenticate }, dispatch),
});

export default connect(null, mapDispatchToProps)(KioskHeader);

KioskHeader.propTypes = {
  actions: PropTypes.object,
  isHome: PropTypes.bool,
  isMobile: PropTypes.bool,
  isPublic: PropTypes.bool,
  kioskCode: PropTypes.string,
  scheduleCode: PropTypes.string,
};
