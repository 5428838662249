import React from 'react';

import PropTypes from 'prop-types';

import { FiltersNpsCategoryIcon } from '@peakon/bedrock/icons/graphic';

import Text from 'components/Text';

import Card from './';

function LearnMoreCard({ loginHref, isRenderedInIframe }) {
  return (
    <Card
      icon={FiltersNpsCategoryIcon}
      title={<Text>employee_dashboard__v2__learn_most</Text>}
      description={<Text>employee_dashboard__v2__learn_desc</Text>}
      action={
        !isRenderedInIframe && (
          <a
            className="peak-btn peak-btn-secondary peak-btn--sm"
            href={loginHref}
          >
            <Text>employee_dashboard__v2__learn_more</Text>
          </a>
        )
      }
      data-test-id="learn-more-card"
    />
  );
}

LearnMoreCard.propTypes = {
  loginHref: PropTypes.string,
  isRenderedInIframe: PropTypes.bool,
};

export default LearnMoreCard;
