import i18n, { TOptions } from 'i18next';
import IntlMessageFormat, {
  ErrorCode,
  MissingValueError,
} from 'intl-messageformat';
import errorReporter from 'errorReporter';
import { employeeLocales } from './localesHelper';

export function getEmployeeScopedLocaleInfo(locale: string) {
  return employeeLocales.resolve(locale);
}

export function getCurrentLocaleInfo() {
  const language = i18n.resolvedLanguage ?? i18n.language;
  return getEmployeeScopedLocaleInfo(language);
}

export const messageFormatProcessor = {
  type: 'postProcessor',
  name: 'messageFormat',
  process: function (str: string, keys: string[], options: TOptions) {
    return format(str, keys, options);
  },
} as const;

function format(
  str: string,
  keys: string[],
  options: TOptions,
  recursionCount = 0,
) {
  const locale = getCurrentLocaleInfo();

  try {
    return new IntlMessageFormat(str, locale.messageFormat).format({
      ...options,
      ...options.replace,
    });
  } catch (e: unknown) {
    // completely arbitrary number, it's just to prevent infinite recursion in case I made a mistake
    const MAX_RECURSION = 20;

    // `intl-messageformat` throws an error when a variable is missing
    // we have historically ignored these errors, so this is just to keep that behavior
    // but we report it to the `errorReporter`
    if (
      e instanceof Error &&
      (e as MissingValueError).code === ErrorCode.MISSING_VALUE &&
      recursionCount < MAX_RECURSION
    ) {
      const variableRegex = /The intl string context variable "(\w+)"/;
      const missingVariable = variableRegex.exec(e.message)?.[1];
      if (missingVariable) {
        // Not reporting on production, strictly because I'm not sure if it'd flood Rollbar
        if (recursionCount === 0 && ENV.environment !== 'production') {
          const key = keys[0];
          // report it only once
          errorReporter.warning(new Error(`For key: ${key}: ${e.message}`));
        }
        return format(
          str,
          keys,
          {
            ...options,
            replace: {
              ...options.replace,
              [missingVariable]: '',
            },
          },
          recursionCount + 1,
        );
      }
    }
    if (e instanceof Error) {
      errorReporter.error(e, { keys, options });
    }

    return str;
  }
}
