import { Record } from 'immutable';
import get from 'lodash/get';
import omit from 'lodash/omit';

import engageHelpers from '@peakon/engage-helpers';

export default class Summary extends Record({
  activity: undefined,
  bottomCategory: undefined,
  changes: undefined,
  enabled: false,
  isLoading: true,
  nextStart: undefined,
  topCategory: undefined,
  activationState: undefined,
  firstSurveyAnswer: undefined,
  employeeDashboardTeaser: undefined,
}) {
  hasChanges() {
    return Boolean(
      this.changes && this.changes.previous && this.changes.previous.difference,
    );
  }

  hasFeedback() {
    return Boolean(
      this.bottomCategory || this.topCategory || this.hasChanges(),
    );
  }

  static create(data, enabled = false) {
    const {
      attributes: {
        activity,
        feedback: { bottomCategory, engagement, topCategory },
        next,
        activationState,
        employeeDashboardTeaser,
      },
    } = data;

    const changes = engageHelpers.changes(
      omit(engagement, ['changes.first.time', 'changes.previous.time']),
    );

    return new Summary({
      activity,
      bottomCategory,
      changes,
      enabled,
      isLoading: false,
      nextStart: next && next.start,
      topCategory,
      activationState,
      firstSurveyAnswer: get(engagement, 'changes.first.time'),
      employeeDashboardTeaser,
    });
  }
}
