import React, { Fragment, useState } from 'react';

import removeMarkdown from 'markdown-to-text';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Button } from '@peakon/components';

import styles from './styles.scss';
import { MarkdownWrapper } from '../MarkdownWrapper/MarkdownWrapper';

const kioskModeSelector = (state) => state.getIn(['kiosk', 'mode']);
const kioskRawDataExportSelector = (state) =>
  state.getIn(['kiosk', 'rawDataExport']);
const surveyRawDataExportSelector = (state) =>
  state.getIn(['survey', 'rawDataExport']);

const getRawDataExport = createSelector(
  [kioskModeSelector, kioskRawDataExportSelector, surveyRawDataExportSelector],
  (kioskMode, kioskRawDataExport, surveyRawDataExport) => {
    return kioskMode ? kioskRawDataExport : surveyRawDataExport;
  },
);

const RawDataExportDisclaimer = () => {
  const { t } = useTranslation();

  // NOTE: the html-ellipsis library will remove one character from the limit when appending the ellipsis
  const CHARACTER_LIMIT = 301;
  const ALLOWED_MARKDOWN_TAGS = ['a', 'strong', 'em'];
  const [isExpanded, setExpanded] = useState(false);
  const rawDataExport = useSelector(getRawDataExport);

  const isRawDataExportEnabled = rawDataExport && rawDataExport.enabled;
  if (!isRawDataExportEnabled) {
    return null;
  }

  const rawDataExportMessage = rawDataExport.message
    ? rawDataExport.message
    : t('survey__data_privacy_text__data_export__confidential');

  return (
    <div
      className={styles.rawDataExportContainer}
      data-test-id="raw-data-export-text"
    >
      {removeMarkdown(rawDataExportMessage).length <= CHARACTER_LIMIT ||
      isExpanded ? (
        <MarkdownWrapper
          source={rawDataExportMessage}
          allowedTags={ALLOWED_MARKDOWN_TAGS}
          openLinksInNewTab
        />
      ) : (
        <Fragment>
          <MarkdownWrapper
            source={rawDataExportMessage}
            allowedTags={ALLOWED_MARKDOWN_TAGS}
            openLinksInNewTab
            truncate={CHARACTER_LIMIT}
          />
          <Button
            type="tertiary"
            onClick={() => setExpanded(true)}
            testId="raw-data-export-expand"
          >
            {t('confidential__expand_disclaimer')}
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default RawDataExportDisclaimer;
