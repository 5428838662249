/**
 * @param {Object} obj
 * @param {string} obj.href
 * @param {string} obj.newSubdomain
 * @param {string} obj.currentSubdomain
 * Returns the href for the primary subdomain, without any transfer token or preserving the session.
 * @example
 * getSubdomainHref({
 *   href: 'https://app.peakon.com/dashboard?foo=bar',
 *   newSubdomain: 'kinetar',
 *   currentSubdomain: 'app',
 * }) // 'https://kinetar.peakon.com/dashboard?foo=bar'
 **/
export const getSubdomainHref = ({ href, newSubdomain, currentSubdomain }) => {
  return href.replace(currentSubdomain, newSubdomain);
};
