import { getOptionAttributesNames } from 'selectors/AttributeSelectors';

import { attributesError } from './attributes';
import {
  userAttributesFetched,
  userAttributesTranslationsFetched,
} from './authenticate';
import { hideNotification, showNotification } from './notification';
import api from '../api';

export function attributesValuesStart() {
  return {
    type: 'ATTRIBUTES_VALUES_START',
  };
}

export function attributesValuesEnd() {
  return {
    type: 'ATTRIBUTES_VALUES_END',
  };
}

export const getUserAttributesValues = () => async (dispatch, getState) => {
  const state = getState();
  const responseId = state.getIn(['authenticate', 'response', 'responseId']);
  const optionAttributes = getOptionAttributesNames(state);

  dispatch(attributesValuesStart());

  try {
    const {
      data: { attributes, relationships },
    } = await api.response.getAttributes(responseId, {
      include: optionAttributes.concat(['attribute']).join(','),
    });

    dispatch(
      userAttributesFetched({
        attributes,
        relationships,
      }),
    );
  } catch (err) {
    dispatch(attributesError(err));
  }

  dispatch(attributesValuesEnd());
};

export const getUserAttributesValuesTranslations =
  () => async (dispatch, getState) => {
    const state = getState();
    const responseId = state.getIn(['authenticate', 'response', 'responseId']);
    const optionAttributes = getOptionAttributesNames(state);

    const {
      data: { relationships },
    } = await api.response.getAttributes(responseId, {
      include: optionAttributes.concat(['attribute']).join(','),
    });

    dispatch(
      userAttributesTranslationsFetched({
        relationships,
      }),
    );
  };

export const resetResponse =
  ({ notification } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const responseId = state.getIn(['authenticate', 'response', 'responseId']);

    await api.post(`/responses/${responseId}/reset`);

    dispatch(
      showNotification({
        type: 'success',
        ...notification,
      }),
    );

    dispatch({
      type: 'RESPONSE_RESETTED',
    });

    setTimeout(() => {
      dispatch(hideNotification());
    }, 4000);
  };
