import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';

import styles from './SystemBanner.scss';

function renderStatus(t) {
  return (
    <div>
      <strong>{t('survey__major_outage__text')}</strong>
      <a
        className={styles.link}
        href="https://status.peakon.com/"
        rel="noreferrer noopener"
        target="_blank"
      >
        {t('survey__major_outage__link')}
      </a>
    </div>
  );
}

export default function SystemBanner({ position = 'top', isStatus, children }) {
  const { t } = useTranslation();
  const classNames = {
    [styles.dark]: Boolean(children),
    [styles.negative]: isStatus,
    [styles.positive]: !isStatus,
  };

  const className = cx(styles.root, styles[position], classNames);

  return (
    <div className={className} data-test-id="system-banner-container">
      <div>{children || (isStatus && renderStatus(t))}</div>
    </div>
  );
}

SystemBanner.propTypes = {
  isStatus: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node,
};
