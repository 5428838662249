import React, { useEffect, useState, useRef } from 'react';

import cx from 'classnames';
import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';
import AutosizeTextarea from 'react-textarea-autosize';

import { KEYBOARD_SHORTCUTS } from 'constants/keyboardShortcuts';

import { Button } from '@peakon/components';

import styles from './QuestionText.scss';

export const QuestionText = ({
  text,
  inputFocused,
  onBlur,
  isMobile,
  onFocus,
  gotoNextQuestion,
  onChange,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(text || '');
  const textAreaRef = useRef(null);

  const Textarea = isMobile ? 'textarea' : AutosizeTextarea;

  const handleFocus = () => {
    onFocus();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 600);
  };

  const onCloseKeyboard = () => {
    textAreaRef.current?.blur();
  };

  useEffect(() => {
    if (!isMobile && textAreaRef.current) {
      Mousetrap(textAreaRef.current).bind(
        KEYBOARD_SHORTCUTS.SHIFT_ENTER,
        (e) => {
          e.preventDefault();
          gotoNextQuestion();
        },
      );
    }

    return () => {
      if (!isMobile) {
        Mousetrap.unbind(KEYBOARD_SHORTCUTS.SHIFT_ENTER);
      }
    };
  }, [gotoNextQuestion, isMobile]);

  useEffect(() => {
    const timeoutId = setTimeout(() => onChange(value), 300);
    return () => clearTimeout(timeoutId);
  }, [value, onChange]);

  const handleBlur = () => {
    if (value !== text) {
      onChange(value);
    }

    onBlur();

    if (isMobile) {
      textAreaRef.current.style.height = '';
    }
  };

  return (
    <div
      className={cx({
        [styles.focused]: inputFocused,
      })}
    >
      <div className={styles.questionContainer}>
        <label htmlFor="comment-textarea-input" className={styles.info}>
          {t('survey__write_answer')}
        </label>
        {isMobile && inputFocused ? (
          <Button
            type="primary"
            onClick={onCloseKeyboard}
            size="small"
            testId="comment-continue"
          >
            {t('survey__answer__comment__continue')}
          </Button>
        ) : null}
      </div>
      <div className={styles.textareaContainer}>
        <Textarea
          ref={textAreaRef}
          id="comment-textarea-input"
          data-test-id="comment-input"
          className={cx('peak-form-text', styles.textarea)}
          onBlur={isMobile ? handleBlur : undefined}
          placeholder={t('survey__answer__text__placeholder')}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          onFocus={isMobile ? handleFocus : undefined}
        />
      </div>
    </div>
  );
};

QuestionText.propTypes = {
  gotoNextQuestion: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  text: PropTypes.string,
  isMobile: PropTypes.bool,
  inputFocused: PropTypes.bool,
};
