import React, { useEffect, useState, forwardRef, useCallback } from 'react';

import cx from 'classnames';
import each from 'lodash/each';
import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';
import AutosizeTextarea from 'react-textarea-autosize';

import { Button } from '@peakon/components';

import styles from './QuestionComment.scss';

const keyboardShortcuts = {
  next: ['shift+enter'],
};

const QuestionComment = ({
  isMobile,
  inputFocused,
  animateInfo,
  comment,
  textareaRef,
  gotoNextQuestion,
  onChange,
  onBlur,
  onFocus,
}) => {
  const [value, setValue] = useState(comment || '');
  const { t } = useTranslation();

  const Textarea = isMobile ? 'textarea' : AutosizeTextarea;

  const handleBlur = () => {
    if (comment !== value) {
      onChange(value);
    }

    onBlur();

    if (isMobile) {
      textareaRef.current.style.height = '';
    }
  };

  const onCloseKeyboard = () => {
    if (textareaRef.current) {
      textareaRef.current.blur();
    }
  };

  const onTextAreaKeyPress = useCallback(
    (e) => {
      e.preventDefault();
      gotoNextQuestion();
    },
    [gotoNextQuestion],
  );

  const handleFocus = () => {
    onFocus();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 600);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => onChange(value), 300);
    return () => clearTimeout(timeoutId);
  }, [value, onChange]);

  useEffect(() => {
    if (!isMobile && textareaRef.current) {
      Mousetrap(textareaRef.current).bind(
        keyboardShortcuts.next,
        onTextAreaKeyPress,
      );
    }

    return () => {
      if (!isMobile) {
        each(keyboardShortcuts, (value) => Mousetrap.unbind(value));
      }
    };
  }, [isMobile, onTextAreaKeyPress, textareaRef]);

  return (
    <div
      className={cx(styles.root, {
        [styles.focused]: inputFocused,
      })}
    >
      <div className={styles.questionContainer}>
        <label
          htmlFor="question-comment-input"
          className={cx(styles.info, { [styles.infoAnimated]: animateInfo })}
        >
          {t('survey__answer__comment__title')}
        </label>
        {isMobile && inputFocused ? (
          <Button
            type="primary"
            onClick={onCloseKeyboard}
            size="small"
            testId="comment-continue"
          >
            {t('survey__answer__comment__continue')}
          </Button>
        ) : null}
      </div>
      <div className={styles.container}>
        <div className={styles.textareaContainer}>
          <Textarea
            ref={textareaRef}
            id="question-comment-input"
            data-test-id="comment-input"
            className={cx('peak-form-text', styles.textarea)}
            onBlur={handleBlur}
            placeholder={t('survey__answer__comment__placeholder')}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onFocus={isMobile ? handleFocus : undefined}
            autoFocus={!isMobile}
          />
        </div>
      </div>
    </div>
  );
};

QuestionComment.propTypes = {
  textareaRef: PropTypes.any,
  gotoNextQuestion: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  comment: PropTypes.string,
  isMobile: PropTypes.bool,
  animateInfo: PropTypes.bool,
  inputFocused: PropTypes.bool,
};

const QuestionCommentWithRef = forwardRef((props, ref) => (
  <QuestionComment textareaRef={ref} {...props} />
));

QuestionCommentWithRef.displayName = QuestionComment.displayName;

export default QuestionCommentWithRef;
