const compact = require('lodash/compact');
const uniq = require('lodash/uniq');

const { employeeLocales } = require('./localesHelper');

const languages = employeeLocales.all();

function findLanguage(locale) {
  return employeeLocales.resolve(locale);
}

function getSupportedLocales() {
  const locales = languages.map((lang) => lang.id);
  const languageLocales = compact(
    locales.map((locale) => locale.split('-')[0]),
  );

  return uniq(locales.concat(languageLocales));
}

module.exports = {
  languages,
  findLanguage,
  getSupportedLocales,
};
