import React, { Fragment, useState, useEffect, useRef } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Portal from 'components/Portal';
import Text from 'components/Text';

import styles from './KioskStopOverlay.scss';

import get from 'lodash/get';
const Mousetrap = require('mousetrap-pause')(require('mousetrap'));
const TIMEOUT = get(ENV, 'timeouts.kioskStopOverlayAutoLogoutSeconds') || 10;

const KioskStopOverlay = ({ onContinue, onStop }) => {
  const [seconds, setSeconds] = useState(TIMEOUT);
  const { t } = useTranslation();
  const intervalRef = useRef();
  const delimiter = uuidv4();

  if (seconds - 1 < 1) {
    clearInterval(intervalRef.current);
    onStop();
  }

  useEffect(() => {
    Mousetrap.pause();

    const rootNode = window.document.getElementById('survey-app');

    if (rootNode) {
      rootNode.classList.add('has-overlay');
      rootNode.setAttribute('aria-hidden', true);
    }

    intervalRef.current = setInterval(
      () => setSeconds((value) => value - 1),
      1000,
    );

    return () => {
      const rootNode = window.document.getElementById('survey-app');

      if (rootNode) {
        rootNode.classList.remove('has-overlay');
        rootNode.setAttribute('aria-hidden', false);
      }

      clearInterval(intervalRef.current);
      Mousetrap.unpause();
    };
  }, []);

  return (
    <Portal>
      <div className={styles.root}>
        <div>
          <h2 className={styles.title}>{t('survey__stop_survey__title')}</h2>
          <Text
            className={styles.text}
            preRender={(translated) => {
              const [start, mid, end] = translated.split(delimiter);

              return (
                <Fragment>
                  {start}
                  <strong>{mid}</strong>
                  {end}
                </Fragment>
              );
            }}
            values={{
              emphasis_start: delimiter,
              emphasis_end: delimiter,
              seconds,
            }}
          >
            {seconds === 1
              ? 'survey__stop_survey__text__second__v2'
              : 'survey__stop_survey__text__seconds__v2'}
          </Text>
          <div className={styles.actions}>
            <a
              className={cx('peak-btn', 'peak-btn--lg', styles.buttonContinue)}
              onClick={onContinue}
              data-test-id="continue-survey-btn"
            >
              {t('survey__stop_survey__continue')}
            </a>

            <a
              className="peak-btn peak-btn-warning peak-btn--lg"
              onClick={onStop}
              data-test-id="stop-survey-btn"
            >
              <span>{t('survey__stop_survey')}</span>
            </a>
          </div>
        </div>
      </div>
    </Portal>
  );
};

KioskStopOverlay.propTypes = {
  onContinue: PropTypes.func,
  onStop: PropTypes.func,
};

export default KioskStopOverlay;
