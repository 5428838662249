import { List } from 'immutable';

const parseAnswers = (answers = new List(), { excludeUnanswered } = {}) => {
  const parsed = (
    excludeUnanswered
      ? answers.filter((answer) => answer.isAnsweredOrSkipped())
      : answers
  ).map((answer) => answer.toJSONApi());

  return parsed.isEmpty() ? [] : parsed.valueSeq().toArray();
};

export default parseAnswers;
