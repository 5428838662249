import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import storejs from 'store';

import { Spinner } from '@peakon/components';

import { setContext } from 'actions/survey';
import ErrorPage from 'components/ErrorPage';
import getAuthTokenKey from 'utils/getAuthTokenKey';

import useHashParams from 'hooks/useHashParams';
import api from '../../api';
import useQueryParams from '../../hooks/useQueryParams';
import styles from './Preview.scss';

export const Preview = ({ match, history, setContext }) => {
  const [error, setError] = useState(null);

  const token = get(match, 'params.token');
  const questionId = get(match, 'params.questionId');

  const queryParams = useQueryParams();
  const contextId = queryParams.get('contextId');
  const segmentIds = queryParams.get('segmentIds');

  const hashParams = useHashParams();
  const score = hashParams.get('score');

  async function transfer() {
    try {
      const response = await api.auth.transfer(token);

      storejs.set(getAuthTokenKey(), response.data.id);
      storejs.remove('survey_preview');

      if (contextId) {
        storejs.set('preview_context', {
          contextId,
          segmentIds,
        });

        setContext(contextId, segmentIds);
      }

      const surveyAnswerPreviewPath = '/survey/answer/preview';
      if (questionId) {
        let scoreParam = '';
        if (score) {
          const parsedScore = parseInt(score);
          if (!isNaN(parsedScore) && parsedScore >= 0 && parsedScore <= 10) {
            scoreParam = `#score=${parsedScore}`;
          }
        }
        history.push(
          `${surveyAnswerPreviewPath}/question/${questionId}/${scoreParam}`,
        );
      } else {
        history.push(surveyAnswerPreviewPath);
      }
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    transfer();
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.root}>
      {error ? <ErrorPage error={error} /> : <Spinner />}
    </div>
  );
};

Preview.propTypes = {
  setContext: PropTypes.func,
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setContext: bindActionCreators(setContext, dispatch),
});

export const PreviewContainer = connect(
  null,
  mapDispatchToProps,
)(withRouter(Preview));
