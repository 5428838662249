import React from 'react';

import classnames from 'classnames';

import styles from './Header.scss';
import KioskHeader from '../KioskHeader';
import LanguagePicker from '../LanguagePicker';
import Context from '../../records/Context';

type HeaderProps = {
  isHome: boolean;
  isKiosk: boolean;
  isMobile?: boolean;
  scheduleCode?: string;
  kioskCode?: string;
  kioskPublic?: boolean;
  hasSurveyBranding?: boolean;
  context: Context;
};

export default function Header({
  isHome,
  isKiosk,
  isMobile,
  scheduleCode,
  kioskCode,
  kioskPublic,
  hasSurveyBranding,
  context,
}: HeaderProps) {
  return (
    <div
      className={classnames(styles.root, {
        [styles.home]: isHome,
        [styles.kiosk]: isKiosk,
        [styles.branded]: hasSurveyBranding,
      })}
    >
      <div className={styles.language}>
        <LanguagePicker isKiosk={isKiosk} />
      </div>
      {isKiosk ? (
        <KioskHeader
          isHome={isHome}
          isMobile={isMobile}
          scheduleCode={scheduleCode}
          kioskCode={kioskCode}
          isPublic={kioskPublic}
        />
      ) : null}
      {hasSurveyBranding && context && context?.logo && (
        <img
          src={`${context.logo}?fit=max&w=150&h=47&dpr=2`}
          alt=""
          className={styles.headerBrandedLogoImage}
        />
      )}
    </div>
  );
}
