import React from 'react';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { NavigationInsightCommentsIcon as CommentIcon } from '@peakon/bedrock/icons/system';
import { IconButton } from '@peakon/bedrock/react/button';

import styles from './CommentButton.scss';

export default function CommentButton({ onClick, hideInfo }) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <IconButton
        accessibleName={t('survey__add_comment')}
        variant="secondary"
        size="large"
        icon={<CommentIcon />}
        rounded
        data-test-id="add-comment-btn"
        id="addCommentBtn"
        onClick={onClick}
      />

      {hideInfo ? null : (
        <label
          htmlFor="addCommentBtn"
          className={styles.help}
          data-test-id="add-comment-link"
        >
          {t('survey__add_comment')}
        </label>
      )}
    </div>
  );
}

CommentButton.propTypes = {
  hideInfo: PropTypes.bool,
  onClick: PropTypes.func,
};
