import { unauthenticate } from './authenticate';
import api from '../api';

export function attributesStart() {
  return {
    type: 'ATTRIBUTES_START',
  };
}

export function attributesEnd() {
  return {
    type: 'ATTRIBUTES_END',
  };
}

export function attributesChecked(status) {
  return {
    type: 'ATTRIBUTES_CHECKED',
    status,
  };
}

export function attributesFetched(attributes) {
  return {
    type: 'ATTRIBUTES_FETCHED',
    attributes,
  };
}

export function attributesWithValueFetched(attributes) {
  return {
    type: 'ATTRIBUTES_WITH_VALUE_FETCHED',
    attributes,
  };
}

export function attributesError(error) {
  return {
    type: 'ATTRIBUTES_ERROR',
    error,
  };
}

export function checkAttributes() {
  return async function (dispatch) {
    let response;

    try {
      response = await api.employee.getAttributeStatus('me');
      dispatch(attributesChecked(response.status));
    } catch (err) {
      dispatch(attributesError(err));
    }

    return response && response.status;
  };
}

export function getAttributes() {
  return async function (dispatch) {
    dispatch(attributesStart());

    try {
      const attributes = await api.attribute.list({
        'filter[status]': 'active',
        'filter[open]': 'true',
      });
      dispatch(attributesFetched(attributes.data));
    } catch (err) {
      dispatch(attributesError(err));
    } finally {
      dispatch(attributesEnd());
    }
  };
}

export function getAttributesWithValue(responseId) {
  return async function (dispatch) {
    dispatch(attributesStart());

    try {
      const response = await api.response.getAttributesWithValue(responseId);
      dispatch(attributesWithValueFetched(response.data));
    } catch (err) {
      dispatch(attributesError(err));
    } finally {
      dispatch(attributesEnd());
    }
  };
}

export function attributesSubmitComplete() {
  return {
    type: 'ATTRIBUTES_SUBMIT_COMPLETE',
  };
}

export const attributesSubmitted = () => async (dispatch) => {
  dispatch(attributesSubmitComplete());
  try {
    await dispatch(unauthenticate());
  } catch (error) {
    // noop
  }
};

export function attributesMessageFetched(message) {
  return {
    type: 'ATTRIBUTES_MESSAGE_FETCHED',
    message,
  };
}

export function setReadOnlyAttributes(attributes) {
  return {
    type: 'ATTRIBUTES_SET_READ_ONLY',
    attributes,
  };
}
