import { useLocation } from 'react-router-dom';

import getQueryFromLocation from '../utils/getQueryFromLocation';

function useQueryParams() {
  const location = useLocation();

  return getQueryFromLocation(location);
}

export default useQueryParams;
