import { Record } from 'immutable';

class Context extends Record({
  id: undefined,
  name: undefined,
  logo: undefined,
}) {
  id?: string;
  name?: string;
  logo?: string;

  static create(data: {
    relationships: {
      brandingContext: {
        id: string;
        attributes: { name: string; logo: string };
      };
    };
  }) {
    const {
      relationships: {
        brandingContext: {
          id,
          attributes: { name, logo },
        },
      },
    } = data;

    return new Context({
      id,
      name,
      logo,
    });
  }
}

export default Context;
