import { List } from 'immutable';
import moment from 'moment';

function useIsEligibleForResearchPanel(summary, addOns = new List(), locale) {
  if (!summary.enabled) {
    return false;
  }

  const days = window.__peakon_test_survey_panel_days || 30;
  const isMoreThan30DaysSinceFirstAnswer =
    moment().diff(moment(summary.firstSurveyAnswer), 'days') > days;

  return (
    addOns.includes('research_panel') &&
    locale.includes('en-') &&
    isMoreThan30DaysSinceFirstAnswer
  );
}

export default useIsEligibleForResearchPanel;
