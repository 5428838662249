import { KIOSK_MODES } from 'constants/kiosk';

import React, { Component, createRef } from 'react';

import cx from 'classnames';
import { ErrorPage, StatusChecker, Notification } from 'components';
import assign from 'lodash/assign';
import get from 'lodash/get';
import MobileDetect from 'mobile-detect';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { bindActionCreators } from 'redux';

import { Spinner } from '@peakon/components';

import * as AuthenticateActions from 'actions/authenticate';
import * as LocaleActions from 'actions/locale';
import * as QuestionsActions from 'actions/questions';
import { resetResponse } from 'actions/response';
import {
  setFirstSurvey,
  unsetFirstSurvey,
  skipToThankYouPage,
  unskipThankYouPage,
} from 'actions/survey';
import {
  mobileDetected,
  nativeDetected,
  setIsRenderedInIFrame,
} from 'actions/ui';
import * as stateUtil from 'lib/stateUtil';
import { getCurrentQuestion } from 'selectors/QuestionSelectors';
import getQueryFromLocation from 'utils/getQueryFromLocation';

import PreviewBanner from './PreviewBanner';
import styles from './Survey.scss';
import transitions from './transitions.scss';
import i18n from '../../features/i18n/i18n';
import { AttributesFormContainer } from '../AttributesForm';
import { HomeContainer } from '../Home';
import { QuestionContainer } from '../Question';
import { ThankYouContainer } from '../ThankYou';

const detector = new MobileDetect(navigator.userAgent);

export class Survey extends Component {
  constructor(props) {
    super(props);

    this.node = createRef();
  }

  UNSAFE_componentWillMount() {
    if (detector.mobile()) {
      this.props.actions.mobileDetected(true);
    }

    if (window.peakon && window.peakon.native) {
      this.props.actions.nativeDetected(true);
    }

    const query = getQueryFromLocation(window.location);
    const iframe = query.get('iframe');

    if (iframe) {
      this.props.actions.setIsRenderedInIFrame(true);
    }

    this.props.actions.initLocale(i18n.language);
  }

  async componentDidMount() {
    const {
      token,
      actions: { authenticate, authenticateFromSession },
    } = this.props;

    const isPreview = this.isPreview();
    const isKiosk = token === 'kiosk';

    if (isPreview || isKiosk || token === 'response') {
      await authenticateFromSession(isPreview, isKiosk);
    } else {
      await authenticate(token);
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      isAuthenticated,
      actions: { fetchNextQuestion, unskipThankYouPage },
      currentQuestion,
      kioskMode,
      isMobile,
      isSkipped,
      token,
      location: { pathname },
      history,
      responseStatus,
    } = this.props;

    const isKiosk = token === 'kiosk';

    const resettedResponse =
      isAuthenticated &&
      prevProps.responseStatus === 'closed' &&
      responseStatus === 'open';

    if (
      (!prevProps.isAuthenticated &&
        isAuthenticated &&
        responseStatus !== 'closed') ||
      resettedResponse
    ) {
      const hasCurrentQuestion = Boolean(currentQuestion);
      const question = await fetchNextQuestion(hasCurrentQuestion);

      if (isKiosk && kioskMode === 'ephemeral') {
        if (hasCurrentQuestion) {
          return history.push(
            `/survey/answer/${token}/question/${question.data.id}`,
          );
        } else {
          return history.push(`/survey/answer/${token}`);
        }
      }
    }

    if (isMobile && this.node.current) {
      this.node.current.scrollTop = 0;
    }

    if (!prevProps.isSkipped && isSkipped) {
      history.push(`/survey/answer/${token}/thankyou`);
    }

    if (!pathname.includes('thankyou') && prevProps.isSkipped && isSkipped) {
      unskipThankYouPage();
    }
  }

  renderNotification() {
    return (
      <CSSTransition classNames={transitions} appear timeout={500}>
        {this.props.showNotification ? (
          <Notification {...this.props.notification} />
        ) : (
          <React.Fragment />
        )}
      </CSSTransition>
    );
  }

  renderPreviewBanner() {
    const { isSkipped, firstSurvey, questionId } = this.props;

    if (!this.isPreview() || questionId) {
      return null;
    }

    return (
      <PreviewBanner
        handlePreviewThankYouPage={this.handlePreviewThankYouPage}
        isSkipped={isSkipped}
        firstSurvey={firstSurvey}
      />
    );
  }

  isPreview() {
    return this.props.token === 'preview';
  }

  handlePreviewThankYouPage = (e) => {
    e.preventDefault();
    const {
      actions: { skipToThankYouPage },
    } = this.props;
    skipToThankYouPage();
  };

  render() {
    const {
      actions: { resetResponse },
      isLoading,
      error,
      submitted,
      isAuthenticated,
      location,
      resetAvailable,
      responseStatus,
      t,
      history,
      token,
    } = this.props;

    // thank you page must be rendered unauthenticated as the survey
    // has already been completed.
    const isThankYouPage = location.pathname.includes('thankyou');

    if (isLoading) {
      return (
        <div className={styles.loading}>
          <Spinner data-test-id="spinner" />
        </div>
      );
    } else if (error || responseStatus === 'closed') {
      return (
        <ErrorPage
          error={error}
          onReset={() => {
            if (isThankYouPage) {
              history.replace(`/survey/answer/${token}`);
            }

            return resetResponse({
              notification: {
                title: t('survey__reset__success__title'),
                message: t('survey__reset__success'),
              },
            });
          }}
          resetAvailable={resetAvailable}
          responseStatus={responseStatus}
        />
      );
    } else {
      const rootClassName = cx(styles.root, {
        [styles.submitted]: submitted,
      });

      return (
        <div ref={this.node} className={rootClassName}>
          {ENV.statusPage ? <StatusChecker /> : null}
          {(isAuthenticated || isThankYouPage) && (
            <div className={styles.content}>
              <Switch>
                <Route
                  path="/survey/answer/:token/attributes"
                  component={AttributesFormContainer}
                />
                <Route
                  path="/survey/answer/:token/question/:questionId"
                  component={QuestionContainer}
                />
                <Route
                  path="/survey/answer/:token/thankyou"
                  component={ThankYouContainer}
                />
                <Route component={HomeContainer} />
              </Switch>
            </div>
          )}
          {this.renderNotification()}
          {this.renderPreviewBanner()}
        </div>
      );
    }
  }
}

Survey.propTypes = {
  actions: PropTypes.object.isRequired,
  currentQuestion: PropTypes.object,
  error: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  token: PropTypes.string.isRequired,
  questionId: PropTypes.string,
  showNotification: PropTypes.bool,
  notification: PropTypes.object,
  locale: PropTypes.string,
  isMobile: PropTypes.bool,
  submitted: PropTypes.bool,
  firstSurvey: PropTypes.bool,
  isSkipped: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
  kioskMode: PropTypes.oneOf(KIOSK_MODES),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  surveyInfo: PropTypes.object,
  surveyQuestionSets: PropTypes.array,
  responseStatus: PropTypes.string,
  resetAvailable: PropTypes.bool,
  t: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const currentQuestion = getCurrentQuestion(state);

  const token = get(props, 'match.params.token');
  const questionId = get(props, 'match.params.questionId');

  return {
    currentQuestion,
    error: stateUtil.toJS(['authenticate', 'error'], state),
    isAuthenticated: state.getIn(['authenticate', 'isAuthenticated']),
    isLoading: state.getIn(['authenticate', 'isAuthenticating']),
    surveyInfo: stateUtil.toJS(['survey', 'info'], state),
    surveyQuestionSets: state.getIn(['survey', 'surveyQuestionSets']),
    firstSurvey: state.getIn(['survey', 'firstSurvey']),
    isSkipped: state.getIn(['survey', 'isSkipped']),
    submitted: state.getIn(['survey', 'submitted']),
    showNotification: state.getIn(['notification', 'show']),
    notification: stateUtil.toJS(['notification', 'data'], state),
    locale: state.getIn(['locale', 'locale']),
    isMobile: state.getIn(['ui', 'isMobile']),
    kioskMode: state.getIn(['kiosk', 'mode']),
    token,
    questionId,
    resetAvailable: stateUtil.toJS(['survey', 'resetAvailable'], state),
    responseStatus: stateUtil.toJS(['survey', 'responseStatus'], state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      assign(
        {
          mobileDetected,
          nativeDetected,
          setIsRenderedInIFrame,
          setFirstSurvey,
          unsetFirstSurvey,
          skipToThankYouPage,
          unskipThankYouPage,
          resetResponse,
        },
        QuestionsActions,
        AuthenticateActions,
        LocaleActions,
      ),
      dispatch,
    ),
  };
};

export const SurveyContainer = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Survey),
);
