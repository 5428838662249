import api from 'api';
import get from 'lodash/get';
import head from 'lodash/head';
import storejs from 'store';

import getAuthTokenKey from 'utils/getAuthTokenKey';

import { authenticateStart, authenticateError } from './authenticate';
import { localesLoaded } from './locale';
import { hideNotification } from './notification';

export const readCode = (scheduleCode) => async (dispatch) => {
  storejs.set('schedule_code', scheduleCode);
  storejs.remove(`kiosk_${scheduleCode}`);

  try {
    const response = await api.schedule.code(scheduleCode, {
      include: 'brandingContext',
      fields: {
        contexts: 'logo,name',
        schedules:
          'brandingContext,kioskMode,locales,kioskPublic,kioskUrl,rawDataExport,confidentialityDisclaimer',
      },
    });

    const locales = get(response, 'data.attributes.locales', []);
    dispatch(localesLoaded(locales, { refetch: false }));

    return dispatch({
      type: 'SCHEDULE_READ',
      data: { schedule: response, scheduleCode },
    });
  } catch (error) {
    dispatch({
      type: 'SCHEDULE_READ_FAILURE',
      data: { schedule: scheduleCode },
    });

    throw error;
  }
};

export const authenticate =
  ({ kioskCode, scheduleCode }) =>
  async (dispatch, getState) => {
    const kioskMode = getState().get('kiosk').mode;

    dispatch(authenticateStart());

    try {
      const response = await api.auth.kiosk(scheduleCode, kioskCode);

      storejs.set(getAuthTokenKey(), response.data.id);
      storejs.set(`kiosk_${scheduleCode}`, { scheduleCode, kioskCode });

      let responseId;

      if (kioskMode === 'employeeCode') {
        const openSurveys = await api.response.employeeOpenScheduleCode(
          scheduleCode,
          { includeResettable: true },
        );

        responseId = get(head(openSurveys.data), 'id');
      } else {
        responseId = response.data.relationships.source.id;
      }

      if (responseId) {
        storejs.set('response_id', responseId);
      }

      return responseId;
    } catch (error) {
      dispatch(authenticateError(error));
      dispatch(hideNotification());

      throw error;
    }
  };

export const employeeLogin =
  ({ kioskCode, firstName, scheduleCode }) =>
  async (dispatch) => {
    dispatch(authenticateStart());

    try {
      const response = await api.auth.kioskEmployee(
        scheduleCode,
        kioskCode,
        firstName,
      );

      storejs.set(getAuthTokenKey(), response.data.id);
      storejs.set(`kiosk_${scheduleCode}`, {
        scheduleCode,
        kioskCode,
        firstName,
      });

      const openSurveys = await api.response.employeeOpenScheduleCode(
        scheduleCode,
        { includeResettable: true },
      );

      const responseId = get(head(openSurveys.data), 'id');

      if (responseId) {
        storejs.set('response_id', responseId);
      }

      return responseId;
    } catch (error) {
      dispatch(authenticateError(error));
      dispatch(hideNotification());

      throw error;
    }
  };

export const createEmployee = (scheduleCode) => async (dispatch) => {
  dispatch(authenticateStart());

  try {
    const response = await api.schedule.createEmployee(scheduleCode);

    storejs.set(getAuthTokenKey(), response.data.id);
    storejs.set(`kiosk_${scheduleCode}`, {
      scheduleCode,
    });

    const responseId = response.data.relationships.source.id;
    storejs.set('response_id', responseId);

    return responseId;
  } catch (error) {
    dispatch(authenticateError(error));

    throw error;
  }
};
