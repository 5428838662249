import React from 'react';

import htmlEllipsis from 'html-ellipsis';
import PropTypes from 'prop-types';
import Markdown from 'react-remarkable';
import sanitizer from 'sanitize-html';

const DEFAULT_ALLOWED_MARKDOWN_TAGS = sanitizer.defaults.allowedTags.filter(
  (tag) => !['img', 'a', 'h1', 'h2'].includes(tag),
);
export function MarkdownWrapper({
  source,
  allowedTags = DEFAULT_ALLOWED_MARKDOWN_TAGS,
  openLinksInNewTab = false,
  options,
  truncate,
}) {
  return (
    <Markdown
      source={source}
      sanitize={(rendered) => {
        const html = truncate
          ? htmlEllipsis(rendered, truncate, true)
          : rendered;

        return sanitizer(html, {
          allowedTags,
          transformTags: openLinksInNewTab
            ? {
                a(tagName, attribs) {
                  return {
                    tagName: 'a',
                    attribs: { ...attribs, target: '_blank' },
                  };
                },
              }
            : {},
        });
      }}
      options={{
        breaks: true,
        html: false,
        ...options,
      }}
    />
  );
}
MarkdownWrapper.propTypes = {
  source: PropTypes.string,
  allowedTags: PropTypes.array,
  openLinksInNewTab: PropTypes.bool,
  options: PropTypes.object,
  truncate: PropTypes.number,
};
