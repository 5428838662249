import { Record } from 'immutable';

export default class Kiosk extends Record({
  isPublic: true,
  mode: undefined,
  scheduleCode: undefined,
  rawDataExport: undefined,
  confidentialityDisclaimer: undefined,
  kioskUrl: undefined,
}) {}
