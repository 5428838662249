// used for animation consistency since we animate each digit separately
const getDigits = (seconds, timeLeft) => {
  const totalMinutes = Math.round(seconds / 60);
  const minutesLeft = Math.round(timeLeft / 60);
  const totalDigits = `${totalMinutes}`.split('');

  let digits = `${minutesLeft}`.split('');

  if (digits.length < totalDigits.length) {
    digits = totalDigits
      .slice(0, totalDigits.length - digits.length)
      .map((d) => '')
      .concat(digits);
  }

  return digits;
};

export const parseProgress = ({ answered, estimated, seconds }) => {
  let timeLeft = seconds;
  if (answered) {
    timeLeft -= (seconds / estimated) * answered;
  }

  const digits = getDigits(seconds, timeLeft);

  const progress = 100 - Math.round((timeLeft * 100) / seconds);

  return {
    digits,
    progress,
  };
};
