import api from '../api';

export function customConsentStatusStart(responseId) {
  return {
    type: 'CUSTOM_CONSENT_STATUS_START',
    responseId,
  };
}

export function customConsentStatusComplete(responseId, responseData) {
  return {
    type: 'CUSTOM_CONSENT_STATUS_COMPLETE',
    responseId,
    responseData,
  };
}

export function customConsentStatusError(responseId, error) {
  return {
    type: 'CUSTOM_CONSENT_STATUS_ERROR',
    responseId,
    error,
  };
}

export const getCustomConsentStatus = (responseId) => async (dispatch) => {
  dispatch(customConsentStatusStart(responseId));

  try {
    if (responseId == null) {
      throw new Error(`Error: responseId is null`);
    }

    const data = await api.get(
      `/custom_consents/status${
        responseId === 'preview' ? '' : `?responseId=${responseId}`
      }`,
    );
    dispatch(customConsentStatusComplete(responseId, data));
  } catch (error) {
    dispatch(customConsentStatusError(responseId, error));
  }
};
