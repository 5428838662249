import { Map } from 'immutable';

export default function customConsentReducer(state = new Map(), action) {
  switch (action.type) {
    case 'CUSTOM_CONSENT_STATUS_START': {
      const { responseId } = action;
      return state.set(responseId, {
        isLoading: true,
      });
    }
    case 'CUSTOM_CONSENT_STATUS_COMPLETE': {
      const { responseId, responseData } = action;
      if (responseData?.data == null) {
        return state.set(responseId, {
          isLoading: false,
          isLoaded: true,
        });
      }

      const { data, included } = responseData;
      const { id, attributes, relationships } = data;

      const attributesMissingConsentData =
        relationships?.attributesMissingConsent?.data ?? [];
      const attributesMissingConsent = attributesMissingConsentData
        .filter((a) => a.type === 'attributes')
        .map(({ id, type }) => ({
          id,
          type,
          ...(included.filter((d) => d.id === id && d.type === type)[0] ?? {}),
        }));

      return state.set(responseId, {
        isLoading: false,
        isLoaded: true,
        customConsentId: id,
        attributes,
        attributesMissingConsent,
      });
    }
    case 'CUSTOM_CONSENT_STATUS_ERROR': {
      const { responseId, error } = action;
      return state.set(responseId, {
        isLoading: false,
        error,
      });
    }
    default:
      return state;
  }
}
