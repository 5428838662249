import React, { Suspense } from 'react';

import { OrderedSet } from 'immutable';
import get from 'lodash/get';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { Button, Spinner } from '@peakon/components';

import * as AttributesActions from 'actions/attributes';
import * as AuthenticateActions from 'actions/authenticate';
import * as NotificationActions from 'actions/notification';
import * as stateUtil from 'lib/stateUtil';
import { getQuestionsWithAnswers } from 'selectors/QuestionSelectors';

import styles from './styles.scss';
import Footer from '../Footer';

const AttributesCollection = React.lazy(() => import('./AttributesCollection'));

const TIMEOUT = get(ENV, 'timeouts.saveAttributesNotification') || 4000;

const Attributes = ({
  attributesActions,
  authenticateActions,
  attributes,
  attributesWithValue,
  contextName,
  isLoading,
  isMobile,
  isPreview,
  locale,
  logo,
  message,
  notificationActions,
  match,
  questions,
  responseId,
  history,
  t,
  user,
  hasStandardMessage,
}) => {
  const previous = () => {
    history.push(`/survey/answer/${match.params.token}`);
  };

  const onSubmit = (data) => {
    const { userAttributesFetched } = authenticateActions;
    const { attributesSubmitComplete } = attributesActions;
    const { hideNotification, showNotification } = notificationActions;

    attributesSubmitComplete();

    const shouldNotify = !isPreview && data;

    if (shouldNotify) {
      userAttributesFetched(data);

      showNotification({
        type: 'success',
        message: t('surveyform__details-updated'),
      });
    }

    const nextQuestion = questions.first();

    if (nextQuestion) {
      history.push(
        `/survey/answer/${match.params.token}/question/${nextQuestion.id}`,
      );
    }

    if (shouldNotify) {
      setTimeout(() => {
        hideNotification();
      }, TIMEOUT);
    }
  };
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {logo && (
          <div className={styles.logoContainer}>
            <img
              className={styles.logo}
              src={`${logo}?fit=max&w=72&dpr=2`}
              alt=""
            />
          </div>
        )}
        {isLoading ? (
          <Spinner data-test-id="spinner" />
        ) : (
          <div className={styles.form}>
            <div className={styles.buttonPreviousContainer}>
              <Button
                type="secondary"
                onClick={previous}
                testId="previous"
                size={isMobile ? 'large' : 'medium'}
              >
                <div>{t('survey__navigation__previous_no_question')}</div>
              </Button>
            </div>
            {attributes.length > 0 && (
              <Suspense fallback={<Spinner />}>
                <AttributesCollection
                  attributesWithValue={attributesWithValue}
                  attributes={attributes}
                  contextName={contextName}
                  isMobile={isMobile}
                  isPreview={isPreview}
                  locale={locale}
                  message={message}
                  onSubmit={onSubmit}
                  responseId={responseId}
                  user={user}
                  hasStandardMessage={hasStandardMessage}
                />
              </Suspense>
            )}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

Attributes.propTypes = {
  attributesActions: PropTypes.object,
  authenticateActions: PropTypes.object,
  attributes: PropTypes.array,
  attributesWithValue: PropTypes.array,
  contextName: PropTypes.string,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isPreview: PropTypes.bool,
  locale: PropTypes.string,
  logo: PropTypes.string,
  message: PropTypes.string,
  notificationActions: PropTypes.object,
  match: PropTypes.object,
  questions: PropTypes.instanceOf(OrderedSet),
  responseId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  t: PropTypes.func,
  user: PropTypes.object,
  hasStandardMessage: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const features = state.get('features');

  return {
    attributes: state.getIn(['attributes', 'data']).toJS(),
    attributesWithValue: state
      .getIn(['attributes', 'attributesWithValue'])
      .toJS(),
    contextName: state.getIn(['survey', 'context', 'name']),
    isMobile: state.getIn(['ui', 'isMobile']),
    isPreview: state.getIn(['survey', 'isPreview']),
    locale: state.getIn(['locale', 'locale']),
    logo: state.getIn(['survey', 'context', 'logo']),
    message: state.getIn(['attributes', 'message']),
    questions: getQuestionsWithAnswers(state),
    responseId: state.getIn(['authenticate', 'response', 'responseId']),
    user: stateUtil.toJS(['authenticate', 'user'], state),
    hasStandardMessage: !features.includes('removeStandardAttributesMessage'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  attributesActions: bindActionCreators(AttributesActions, dispatch),
  authenticateActions: bindActionCreators(AuthenticateActions, dispatch),
  notificationActions: bindActionCreators(NotificationActions, dispatch),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Attributes)),
);
