import { createSelector } from 'reselect';

const attributesSelector = (state) => state.getIn(['attributes', 'data']);

export const getOptionAttributesNames = createSelector(
  [attributesSelector],
  (attributes) =>
    attributes
      .filter(
        (attribute) => attribute.getIn(['attributes', 'type']) === 'option',
      )
      .map((attribute) => attribute.getIn(['attributes', 'name'])),
);
