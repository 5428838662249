import { combineReducers } from 'redux-immutable';

import answers from './answers';
import attributes from './attributes';
import authenticate from './authenticate';
import context from './context';
import customConsent from './customConsent';
import features from './features';
import kiosk from './kiosk';
import locale from './locale';
import notification from './notification';
import questions, { questionState } from './questions';
import summary from './summary';
import survey from './survey';
import ui from './ui';

const rootReducer = combineReducers({
  attributes,
  authenticate,
  answers,
  context,
  customConsent,
  features,
  kiosk,
  locale,
  questions,
  questionState,
  summary,
  survey,
  notification,
  ui,
});

export default rootReducer;
