import { Kiosk } from 'records';

import {
  RawDataExportRecord,
  ConfidentialityDisclaimerRecord,
} from '../records/Survey';

// prettier-ignore
export default function kiosk(state = new Kiosk(), action) {
  switch (action.type) {
    case 'SCHEDULE_READ': {
      const {
        data: {
          scheduleCode,
          schedule: {
            data: {
              attributes: { kioskMode, kioskPublic, rawDataExport, confidentialityDisclaimer, kioskUrl },
            },
          },
        },
      } = action;

      return state.merge({
        mode: kioskMode,
        isPublic: kioskPublic,
        kioskUrl,
        scheduleCode,
        rawDataExport: new RawDataExportRecord(rawDataExport),
        confidentialityDisclaimer: new ConfidentialityDisclaimerRecord(
          confidentialityDisclaimer
        ),
      });
    }
    default:
      return state;
  }
}
