import React from 'react';

import PropTypes from 'prop-types';

import { ActionsMcpActionsIcon as ActionsIcon } from '@peakon/bedrock/icons/system';

import Text from 'components/Text';

import Card from './';

function ActionsCard({
  count,
  hasEmployeeDashboard,
  loginHref,
  isRenderedInIframe,
}) {
  return (
    <Card
      icon={ActionsIcon}
      title={
        <Text values={{ count }} interpolate>
          employee_dashboard__v2__actions
        </Text>
      }
      description={
        <Text values={{ count }} interpolate>
          employee_dashboard__v2__actions_desc
        </Text>
      }
      action={
        hasEmployeeDashboard &&
        !isRenderedInIframe && (
          <a
            className="peak-btn peak-btn-secondary peak-btn--sm"
            href={loginHref}
          >
            <Text>employee_dashboard__v2__actions_see</Text>
          </a>
        )
      }
      data-test-id="actions-card"
    />
  );
}

ActionsCard.propTypes = {
  count: PropTypes.number,
  hasEmployeeDashboard: PropTypes.bool,
  loginHref: PropTypes.string,
  isRenderedInIframe: PropTypes.bool,
};

export default ActionsCard;
