import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { NotFound } from './components/ErrorPage';
import { Employee } from './containers/Employee';
import { KioskContainer } from './containers/Kiosk';
import { PreviewContainer } from './containers/Preview';
import { SurveyContainer } from './containers/Survey';

export const Routes = () => {
  return (
    <Switch>
      <Route
        path="/survey/preview/:token/question/:questionId"
        component={PreviewContainer}
      />
      <Route path="/survey/preview/:token" component={PreviewContainer} />
      <Route path="/survey/employee/:token/:responseId" component={Employee} />
      <Route path="/survey/answer/:token" component={SurveyContainer} />
      <Route path="/survey/kiosk/:scheduleCode" component={KioskContainer} />
      <Route component={NotFound} />
    </Switch>
  );
};
