/**
 * Checks if the current hostname is a preview app.
 * @example
 * isPreviewApp() // hostname: 'kinetar.peakon.com' ==> false
 * isPreviewApp() // hostname: 'kinetar.peakon.com' ==> false
 * isPreviewApp() // hostname: 'kinetar-1234.preview.peakon.com' ==> true
 **/
export const isPreviewApp = (hostname = window.location.hostname) => {
  return /-\d*.preview.staging-peakon/.test(hostname);
};
