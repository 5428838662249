import React from 'react';

import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

import styles from './styles.scss';

const Clock = ({ children, progress }) => (
  <div className={styles.root} data-test-id="clock">
    <div className={styles.bar}>
      <CircularProgressbar value={progress} />
    </div>
    {children}
  </div>
);

Clock.propTypes = {
  children: PropTypes.node.isRequired,
  progress: PropTypes.number.isRequired,
};

export default Clock;
