import React, { useState, useEffect } from 'react';

import get from 'lodash/get';
import { useHistory, useParams } from 'react-router';
import storejs from 'store';

import { Spinner } from '@peakon/bedrock/react/spinner';

import ErrorPage from 'components/ErrorPage';
import { SURVEY_SUBDOMAIN } from 'utils/features/subdomains/constants';
import { getCurrentSubdomain } from 'utils/features/subdomains/getSubdomain';
import { getSubdomainHref } from 'utils/features/subdomains/getSubdomainHref';
import getAuthTokenKey from 'utils/getAuthTokenKey';
import { assignWindowLocation } from 'utils/window';

import styles from './Employee.scss';
import api from '../../api';

const getPrimarySubdomain = async () => {
  const session = await api.root.session({
    include: 'company,company.companySubdomains',
    fields: {
      companies: 'companySubdomains',
    },
  });

  const subdomains = get(session, [
    'data',
    'relationships',
    'company',
    'relationships',
    'companySubdomains',
  ]);

  if (!subdomains) {
    return;
  }

  const primarySubdomain = subdomains.find(
    (subdomain) => subdomain.attributes.primary,
  );

  return get(primarySubdomain, ['attributes', 'subdomain']);
};

const getSubdomainTransferUrl = async (primarySubdomain, responseId) => {
  const { token } = await api.post('/auth/transfer/generate');

  const href = new URL(
    `${window.location.origin}/survey/employee/${token}/${responseId}`,
  ).href;

  return getSubdomainHref({
    href,
    newSubdomain: primarySubdomain,
    currentSubdomain: SURVEY_SUBDOMAIN,
  });
};

export const Employee = () => {
  const history = useHistory();
  const { token, responseId } = useParams();
  const [error, setError] = useState(null);

  useEffect(() => {
    let response;

    const handleTransferToken = async () => {
      try {
        response = await api.auth.transfer(token);
        storejs.set(getAuthTokenKey(), response.data.id);
        storejs.set('response_id', responseId);

        // generate a new transfer token if the company has a subdomain
        // and the original transfer was originated via SURVEY_SUBDOMAIN
        // https://workday-dev.slack.com/archives/C01TDUL1RU5/p1696578233919279
        const currentSubdomain = getCurrentSubdomain();

        if (currentSubdomain === SURVEY_SUBDOMAIN) {
          const primarySubdomain = await getPrimarySubdomain();

          if (primarySubdomain) {
            const subdomainTransferUrl = await getSubdomainTransferUrl(
              primarySubdomain,
              responseId,
            );
            return assignWindowLocation(subdomainTransferUrl);
          }
        }
        const searchParams = new URLSearchParams(history.location.search);
        history.push(`/survey/answer/response?${searchParams.toString()}`);
      } catch (error) {
        setError(error);
      }
    };

    handleTransferToken();
  }, [history, responseId, token]);

  return (
    <div className={styles.root}>
      {error ? <ErrorPage error={error} /> : <Spinner />}
    </div>
  );
};
