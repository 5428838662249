import storejs from 'store';

import { SUBMIT_ANSWERS } from 'constants/mixpanelEvents';
import parseAnswers from 'lib/parseAnswers';
import { analytics } from 'utils/features/analytics/analytics';

import api from '../api';
import { formatSurveyFrequency } from '../helpers/DateHelper';

export function submitStart() {
  return {
    type: 'SURVEY_SUBMIT_START',
  };
}

export function submitComplete() {
  return {
    type: 'SURVEY_SUBMIT_COMPLETE',
  };
}

export function submitError(error) {
  return {
    type: 'SURVEY_SUBMIT_ERROR',
    error,
  };
}

export function confirmSubmit() {
  return {
    type: 'SURVEY_CONFIRM_SUBMIT',
  };
}

export function cancelSubmit() {
  return {
    type: 'SURVEY_CANCEL_SUBMIT',
  };
}

export function nextStartFetched(nextStart) {
  return {
    type: 'SURVEY_NEXT_START_FETCHED',
    nextStart,
  };
}

export function illustrationsDisabled() {
  return {
    type: 'SURVEY_ILLUSTRATIONS_DISABLED',
  };
}

export function submit() {
  return async function (dispatch, getState) {
    const state = getState();
    const isPreview = state.getIn(['survey', 'isPreview']);
    const frequency = state.getIn(['survey', 'info', 'frequency']);

    const responseId = state.getIn(['authenticate', 'response', 'responseId']);
    const answers = state.getIn(['answers']);

    const mode = state.getIn(['survey', 'isKiosk']) ? 'kiosk' : 'default';
    const platform = window.peakon?.native ? 'mobile' : 'web';
    const questionSets = state.getIn(['survey', 'surveyQuestionSets']);

    let submitted = false;

    dispatch(submitStart());

    if (isPreview) {
      dispatch(submitComplete());

      if (frequency) {
        dispatch(nextStartFetched(formatSurveyFrequency(frequency)));
      }

      submitted = true;
    } else {
      try {
        const parsedAnswers = parseAnswers(answers);
        const nextSchedule = await api.response.submitAnswers(
          responseId,
          parsedAnswers,
          platform,
          mode,
        );
        dispatch(submitComplete());
        if (nextSchedule && nextSchedule.start) {
          dispatch(nextStartFetched(nextSchedule.start));
        }
        storejs.remove(`survey_${responseId}`);
        storejs.remove('response_id');
        submitted = true;

        if (questionSets?.size > 0) {
          // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
          analytics.track(SUBMIT_ANSWERS, {
            questions: parsedAnswers.length,
            scores: parsedAnswers.filter((a) => a.attributes?.scale).length,
            comments: parsedAnswers.filter((a) => a.attributes?.comment).length,
            category_groups: questionSets.toJS(),
          });
        } else {
          // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
          analytics.track(SUBMIT_ANSWERS, {
            questions: parsedAnswers.length,
            scores: parsedAnswers.filter((a) => a.attributes?.scale).length,
            comments: parsedAnswers.filter((a) => a.attributes?.comment).length,
          });
        }

        await completeRawDataExportUXFlow(state);
      } catch (err) {
        dispatch(submitError(err));
      }
    }

    return submitted;
  };
}

export const updateSurveyDuration = (info) => ({
  type: 'SURVEY_DURATION_UPDATED',
  data: info,
});

export const updateSurveyQuestionSets = (questionSets) => ({
  type: 'SURVEY_QUESTION_SETS_UPDATED',
  data: questionSets,
});

export function setPreview() {
  return {
    type: 'SURVEY_SET_PREVIEW',
  };
}

export function setContext(contextId, segmentIds) {
  return {
    type: 'SURVEY_SET_CONTEXT',
    contextId,
    segmentIds,
  };
}

export function setKiosk() {
  return {
    type: 'SURVEY_SET_KIOSK',
  };
}

export function setKioskCode(scheduleCode, kioskCode) {
  return {
    type: 'SURVEY_SET_KIOSK_CODE',
    scheduleCode,
    kioskCode,
  };
}

export function setFirstSurvey() {
  return {
    type: 'SURVEY_SET_FIRST_SURVEY',
  };
}

export function unsetFirstSurvey() {
  return {
    type: 'SURVEY_UNSET_FIRST_SURVEY',
  };
}

export function skipToThankYouPage() {
  return {
    type: 'SURVEY_SKIP_TO_THANK_YOU_PAGE',
  };
}

export function unskipThankYouPage() {
  return {
    type: 'SURVEY_UNSKIP_THANK_YOU_PAGE',
  };
}

async function completeRawDataExportUXFlow(state) {
  try {
    const rawDataExportEnabled = state.getIn([
      'survey',
      'rawDataExport',
      'enabled',
    ]);
    const kioskMode = state.getIn(['kiosk', 'mode']);
    if (rawDataExportEnabled && !kioskMode) {
      await api.uxflow.complete('employee', 'hasSeenRawDataExportMessage');
    }
  } catch (err) {
    // no-op
  }
}
