import Immutable from 'immutable';

const initialState = Immutable.fromJS({
  isMobile: false,
  isNative: false,
  isRenderedInIframe: false,
  inputFocused: false,
});

export default function ui(state = initialState, action) {
  switch (action.type) {
    case 'UI_MOBILE_DETECTED':
      return state.set('isMobile', action.isMobile);

    case 'UI_NATIVE_DETECTED':
      return state.set('isNative', action.isNative);

    case 'UI_IFRAME_DETECTED':
      return state.set('isRenderedInIframe', action.isRenderedInIframe);

    case 'UI_INPUT_FOCUSED':
      return state.set('inputFocused', true);

    case 'UI_INPUT_BLURRED':
      return state.set('inputFocused', false);

    default:
      return state;
  }
}
