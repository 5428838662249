import flatten from 'lodash/flatten';
import { v4 as uuidv4 } from 'uuid';

const preRender = (translated, delimiters) => {
  let result = [translated];

  while (delimiters.length > 0) {
    const { delimiter, render } = delimiters.shift();

    const index = result.findIndex(
      (item) => typeof item === 'string' && item.includes(delimiter),
    );

    if (index !== -1) {
      const [prefix, message, suffix] = result[index].split(delimiter);
      result[index] = [prefix, render(message), suffix];
    }

    result = flatten(result);
  }

  return result.filter((item) => item !== '');
};

export const getTranslationFragments = (t, key, renderFns = {}) => {
  const delimiterKeys = Object.keys(renderFns);

  const delimiters = delimiterKeys.reduce((acc, curr) => {
    acc[curr] = uuidv4();
    return acc;
  }, {});

  // eslint-disable-next-line @peakon/peakon/use-plain-string-translation-key
  const translated = t(key, delimiters);

  const fns = delimiterKeys.map((key) => ({
    delimiter: delimiters[key],
    render: renderFns[key],
  }));

  return preRender(translated, fns);
};

export default preRender;
