import React, { useEffect, useState } from 'react';
import PeakonStatus from '@peakon/status';
import SystemBanner from '../SystemBanner';

const STATUS_MAP = {
  OPERATIONAL: 'operational',
  MAJOR_OUTAGE: 'major_outage',
};

export const StatusCheckerComponent = () => {
  const [currentStatus, setCurrentStatus] = useState(STATUS_MAP.OPERATIONAL);

  useEffect(() => {
    let timeoutId;

    const statusChecker = new PeakonStatus(ENV.statusPage.host, [
      'Survey',
      'Api',
    ]);

    const check = async () => {
      try {
        const status = await statusChecker.check();
        const duration = status === STATUS_MAP.MAJOR_OUTAGE ? 1 : 15;

        setCurrentStatus(status);
        timeoutId = setTimeout(check, duration * 60 * 1000);
      } catch (err) {
        // noop
      }
    };

    check();

    return () => clearTimeout(timeoutId);
  }, []);

  if (currentStatus === STATUS_MAP.MAJOR_OUTAGE) {
    return <SystemBanner isStatus />;
  }

  return null;
};

export default StatusCheckerComponent;
