import React, { Component } from 'react';

import classnames from 'classnames';
import { Header, Submit, QuestionItem, ProgressBar } from 'components';
import { List, OrderedSet } from 'immutable';
import assign from 'lodash/assign';
import each from 'lodash/each';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import storejs from 'store';

import { Spinner } from '@peakon/components';

import * as QuestionActions from 'actions/questions';
import * as SurveyActions from 'actions/survey';
import * as UIActions from 'actions/ui';
import {
  getQuestionsWithAnswers,
  getCurrentQuestion,
  getCurrentQuestionIndex,
  getPreviousQuestion,
  getNextQuestion,
  getCurrentQuestionGroup,
} from 'selectors/QuestionSelectors';
import getQueryFromLocation from 'utils/getQueryFromLocation';

import styles from './Question.scss';

const keyboardShortcuts = {
  next: ['shift+enter', 'down'],
  previous: ['up'],
  cancel: ['esc'],
};

export class Question extends Component {
  constructor() {
    super();

    this.state = {
      answeredFromUrl: false,
    };
  }

  componentDidMount() {
    const { submitted, match, isMobile } = this.props;

    if (submitted) {
      window.location.assign(`/survey/answer/${match.params.token}`);
      return;
    }

    this.changeQuestion();

    if (!isMobile) {
      this.bindKeyboardShortcuts();
    }
  }

  componentWillUnmount() {
    if (!this.props.isMobile) {
      each(keyboardShortcuts, (value) => Mousetrap.unbind(value));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { questionId: prevQuestionId },
      },
      currentQuestion: prevCurrentQuestion,
    } = prevProps;
    const {
      match: {
        params: { questionId },
      },
      currentQuestion,
    } = this.props;

    if (
      prevQuestionId !== questionId ||
      (!prevCurrentQuestion && currentQuestion)
    ) {
      this.changeQuestion();
    }
  }

  ensureConfidentialRedirect() {
    const { rawDataExport, match, history } = this.props;

    if (
      get(rawDataExport, 'enabled') &&
      get(rawDataExport, 'hasSeenRawDataExportMessage') === false
    ) {
      const hasSeenInSession = storejs.get('RAW_DATA_EXPORT_MESSAGE_SEEN');
      if (!hasSeenInSession) {
        history.push(`/survey/answer/${match.params.token}`);
        return true;
      }
    }
  }

  bindKeyboardShortcuts() {
    Mousetrap.bind(keyboardShortcuts.next, (e) => {
      e.preventDefault();
      this.gotoNextQuestion();
    });

    Mousetrap.bind(keyboardShortcuts.previous, (e) => {
      e.preventDefault();
      this.gotoPreviousQuestion();
    });

    Mousetrap.bind(keyboardShortcuts.cancel, (e) => {
      if (this.props.isSkipping) {
        e.preventDefault();
        this.onQuestionSkipCancel();
      }
    });
  }

  onQuestionSkipCancel = () => {
    const { actions, currentQuestion } = this.props;

    actions.questionSkipCancel(currentQuestion.id);
  };

  changeQuestion() {
    const {
      actions,
      currentQuestion,
      match: { params },
      questions,
      history,
    } = this.props;

    if (currentQuestion) {
      this.answerFromUrl();
    }

    if (currentQuestion && currentQuestion.id === params.questionId) {
      return; /* eslint-disable-line no-useless-return */
    } else if (questions.some((q) => q.id === params.questionId)) {
      actions.questionChanged(params.questionId);
    } else if (currentQuestion) {
      history.push(
        `/survey/answer/${params.token}/question/${currentQuestion.id}`,
      );
    } else if (questions && !questions.isEmpty()) {
      // This means the current question id is not valid. Let's redirect the user
      // to the first not-answered question or the last one.
      const nextQuestion =
        questions.find((question) => !question.answer.isAnsweredOrSkipped()) ||
        questions.last();

      history.push(
        `/survey/answer/${params.token}/question/${nextQuestion.id}`,
      );
    }
  }

  getScoreFromUrl() {
    const { location } = this.props;
    const queryParams = getQueryFromLocation(location);

    if (queryParams?.get('score')) {
      const score = parseInt(queryParams.get('score'), 10);
      if (isNumber(score) && score >= 0 && score <= 10) {
        return score;
      }
    }

    if (location?.hash?.includes('score')) {
      const score = parseInt(location.hash.split('=')[1], 10);
      if (isNumber(score) && score >= 0 && score <= 10) {
        return score;
      }
    }

    return false;
  }

  answerFromUrl() {
    const {
      actions,
      attributes,
      currentQuestion,
      location,
      match: { params },
      history,
    } = this.props;

    const score = this.getScoreFromUrl();
    const utmSource = getQueryFromLocation(location).get('utm_source');

    const hasScore = isNumber(score);

    if (!hasScore) {
      // ensure we return early to stop further execution
      if (this.ensureConfidentialRedirect()) {
        return;
      }
    }

    if (
      hasScore &&
      currentQuestion.id === params.questionId &&
      (currentQuestion.type === 'scale' || currentQuestion.type === 'value') &&
      currentQuestion.answer.scale !== score
    ) {
      actions.questionAnswered(currentQuestion.answer.set('scale', score));

      // ensure we return early to stop further execution
      if (this.ensureConfidentialRedirect()) {
        return;
      }

      // redirect to attributes form when user lands via email
      if (attributes && !attributes.isEmpty()) {
        return history.push(
          `/survey/answer/${params.token}/attributes?score=${score}`,
        );
      }

      this.setState({
        answeredFromUrl: true,
      });
    } else if (utmSource && attributes && !attributes.isEmpty()) {
      return history.push(`/survey/answer/${params.token}/attributes`);
    }
  }

  gotoPreviousQuestion = () => {
    const {
      actions,
      attributes,
      confirmSubmit,
      isLoading,
      isSkipping,
      isSubmitting,
      match: { params },
      previousQuestion,
      history,
    } = this.props;

    if (isLoading || isSubmitting) {
      return; /* eslint-disable-line no-useless-return */
    } else if (isSkipping) {
      this.onQuestionSkipCancel();
    } else if (confirmSubmit) {
      actions.cancelSubmit();
    } else if (previousQuestion) {
      history.push(
        `/survey/answer/${params.token}/question/${previousQuestion.id}`,
      );
    } else if (attributes && !attributes.isEmpty()) {
      history.push(`/survey/answer/${params.token}/attributes`);
    } else {
      history.push(`/survey/answer/${params.token}`);
    }
  };

  gotoNextQuestion = async () => {
    const {
      actions,
      confirmSubmit,
      currentQuestion,
      isLoading,
      isSkipping,
      isSubmitting,
      nextQuestion,
      match: { params },
      history,
    } = this.props;

    if (isSkipping || confirmSubmit || isLoading || isSubmitting) {
      return;
    }

    if (!isEmpty(currentQuestion)) {
      const { answer } = currentQuestion;
      if (
        answer &&
        !isNumber(answer.scale) &&
        !answer.text &&
        !answer.comment &&
        !answer.skipReason
      ) {
        return actions.questionSkipStart();
      }
    }

    if (nextQuestion) {
      return history.push(
        `/survey/answer/${params.token}/question/${nextQuestion.id}`,
      );
    }

    await this.getNextQuestion();
  };

  onChangeQuestion = (id) => {
    const {
      history,
      match: {
        params: { token },
      },
    } = this.props;

    history.push(`/survey/answer/${token}/question/${id}`);
  };

  async getNextQuestion() {
    const {
      actions: { fetchNextQuestion, confirmSubmit },
      error,
      history,
      match: { params },
    } = this.props;

    const response = await fetchNextQuestion();

    if (error) {
      return; /* eslint-disable-line no-useless-return */
    } else if (response === null) {
      confirmSubmit();
    } else if (response) {
      history.push(
        `/survey/answer/${params.token}/question/${response.data.id}`,
      );
    }
  }

  submit = (isPrevious = false) => {
    const { currentQuestion, actions, isSkipping } = this.props;

    if (!isSkipping) {
      actions.questionAnswered(currentQuestion.answer);
    }

    if (isPrevious === true) {
      // ensure not truthy param is passed
      this.gotoPreviousQuestion();
    } else {
      this.gotoNextQuestion();
    }
  };

  renderSubmit() {
    const {
      actions,
      confirmSubmit,
      isMobile,
      isPreview,
      isSubmitting,
      match,
      questions,
      history,
    } = this.props;

    if (!confirmSubmit) {
      return null;
    } else {
      const answeredQuestions = questions.filter(
        (q) => isNumber(q.answer.scale) || q.answer.text,
      ).size;
      const isBelowThreshold =
        answeredQuestions < Math.floor(questions.size / 2);

      return (
        <Submit
          onCancel={actions.cancelSubmit}
          onSubmit={actions.submit}
          gotoThankyouPage={() =>
            history.push(`/survey/answer/${match.params.token}/thankyou`)
          }
          isSubmitting={isSubmitting}
          isMobile={isMobile}
          isPreview={isPreview}
          isBelowThreshold={isBelowThreshold}
        />
      );
    }
  }

  renderQuestionOrSpinner() {
    const {
      actions,
      confirmSubmit,
      currentQuestionGroup,
      currentQuestion,
      currentQuestionIndex,
      estimated,
      hideIllustrations,
      inputFocused,
      isLoading,
      isMobile,
      isSkipping,
      nextQuestion,
      previousQuestion,
      rawDataExport,
    } = this.props;

    if (isLoading) {
      return (
        <div className={styles.loader}>
          <Spinner />
        </div>
      );
    } else {
      return (
        <QuestionItem
          question={currentQuestion}
          isLoading={isLoading}
          isMobile={isMobile}
          onAnswer={actions.questionAnswered}
          onScaleSet={actions.questionScaleSet}
          onScaleUnset={actions.questionScaleUnset}
          onComment={actions.questionCommented}
          onClick={actions.toggleComment}
          onSkipStart={actions.questionSkipStart}
          onSkipCancel={actions.questionSkipCancel}
          onSkip={actions.questionSkipped}
          isSkipping={isSkipping}
          confirmSubmit={confirmSubmit}
          onSubmit={this.submit}
          onFocus={actions.inputFocused}
          onBlur={actions.inputBlurred}
          inputFocused={inputFocused}
          hasPreviousQuestion={Boolean(previousQuestion)}
          hasNextQuestion={Boolean(nextQuestion)}
          hideIllustrations={hideIllustrations}
          questionIndex={currentQuestionIndex}
          estimated={estimated}
          rawDataExport={rawDataExport}
          questionGroup={currentQuestionGroup}
        />
      );
    }
  }

  render() {
    const {
      estimated,
      hideIllustrations,
      isKiosk,
      isMobile,
      kioskCode,
      kioskPublic,
      scheduleCode,
      submitted,
      t,
    } = this.props;

    if (submitted) {
      return null;
    } else {
      return (
        <div
          className={classnames(styles.root, {
            [styles.noIllustrations]: hideIllustrations,
          })}
        >
          <div
            role="contentinfo"
            aria-label={t('aria__language_selector')}
            className={styles.header}
          >
            <Header
              scheduleCode={scheduleCode}
              kioskCode={kioskCode}
              kioskPublic={kioskPublic}
              isKiosk={isKiosk}
              isMobile={isMobile}
            />
          </div>
          {this.renderQuestionOrSpinner()}
          {this.renderSubmit()}
          {estimated && this.renderProgressBar()}
        </div>
      );
    }
  }

  renderProgressBar() {
    return <ProgressBar onChangeQuestion={this.onChangeQuestion} />;
  }
}

Question.propTypes = {
  actions: PropTypes.object,
  attributes: PropTypes.instanceOf(List),
  t: PropTypes.func,
  currentQuestionGroup: PropTypes.string,
  currentQuestion: PropTypes.object,
  currentQuestionIndex: PropTypes.number,
  previousQuestion: PropTypes.object,
  nextQuestion: PropTypes.object,
  isLoading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      token: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  confirmSubmit: PropTypes.bool,
  questions: PropTypes.instanceOf(OrderedSet),
  estimated: PropTypes.number,
  isSkipping: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  error: PropTypes.object,
  submitted: PropTypes.bool,
  isMobile: PropTypes.bool,
  inputFocused: PropTypes.bool,
  isKiosk: PropTypes.bool,
  kioskCode: PropTypes.string,
  scheduleCode: PropTypes.string,
  hideIllustrations: PropTypes.bool,
  isPreview: PropTypes.bool,
  kioskPublic: PropTypes.bool,
  rawDataExport: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    attributes: state.getIn(['attributes', 'data']),
    confirmSubmit: state.getIn(['survey', 'confirmSubmit']),
    currentQuestionGroup: getCurrentQuestionGroup(state),
    currentQuestion: getCurrentQuestion(state),
    currentQuestionIndex: getCurrentQuestionIndex(state),
    previousQuestion: getPreviousQuestion(state),
    nextQuestion: getNextQuestion(state),
    isLoading: state.getIn(['questionState', 'isLoading']),
    isSkipping: state.getIn(['questionState', 'isSkipping']),
    questions: getQuestionsWithAnswers(state),
    estimated: state.getIn(['survey', 'info', 'estimated']),
    isSubmitting: state.getIn(['survey', 'isSubmitting']),
    error: state.getIn(['questionState', 'error']),
    submitted: state.getIn(['survey', 'submitted']),
    isMobile: state.getIn(['ui', 'isMobile']),
    inputFocused: state.getIn(['ui', 'inputFocused']),
    isKiosk: state.getIn(['survey', 'isKiosk']),
    kioskCode: state.getIn(['survey', 'kioskCode']),
    scheduleCode: state.getIn(['survey', 'scheduleCode']),
    hideIllustrations: state.getIn(['survey', 'hideIllustrations']),
    isPreview: state.getIn(['survey', 'isPreview']),
    features: state.get('features'),
    kioskPublic: state.getIn(['kiosk', 'isPublic']),
    rawDataExport: state.getIn(['survey', 'rawDataExport']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      assign({}, QuestionActions, SurveyActions, UIActions),
      dispatch,
    ),
  };
};

export const QuestionContainer = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Question)),
);
