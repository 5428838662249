import storejs from 'store';

export const getStoredState = (responseId) => {
  try {
    return storejs.get(`survey_${responseId}`);
  } catch (err) {
    return null;
  }
};

const createPersistor = (getState, timeout) => {
  let timer;

  function persist() {
    const state = getState();
    const submitted = state.getIn(['survey', 'submitted']);
    const isPublicKiosk = state.getIn(['kiosk', 'isPublic']);
    const isKiosk = state.getIn(['survey', 'isKiosk']);

    // Cleanup the potential survey answers that have been left in the browser from previous surveys
    // https://peakon.atlassian.net/browse/SUP-357
    if (isKiosk && isPublicKiosk) {
      storejs.each((_, key) => {
        if (key.includes('survey_')) {
          storejs.remove(key);
        }
      });

      // We don't want to store any user data in the store on kiosk public mode since the device will be shared
      return;
    }

    if (submitted || !storejs.enabled) {
      return;
    }

    const isAuthenticated = state.getIn(['authenticate', 'isAuthenticated']);
    const coverInfo = state.getIn(['survey', 'coverInfo']);
    const info = state.getIn(['survey', 'info']);
    const context = state.getIn(['survey', 'context']);
    const surveyQuestionSets = state.getIn(['survey', 'surveyQuestionSets']);

    if (isAuthenticated && info && context) {
      const data = {
        answers: state.getIn(['answers']).toJS(),
        questionsV2: state.getIn(['questions']).toJS(),
        info: info.toJS(),
        coverInfo: coverInfo ? coverInfo.toJS() : info.toJS(),
        surveyQuestionSets: surveyQuestionSets ? surveyQuestionSets.toJS() : [],
        context: context.toJS(),
        locale: state.getIn(['locale', 'locale']),
        survey: {
          anonymityLevel: state.getIn(['survey', 'anonymityLevel']),
        },
      };
      storejs.set(
        `survey_${state.getIn(['authenticate', 'response', 'responseId'])}`,
        data,
      );
    }
  }

  return () => {
    clearTimeout(timer);
    timer = setTimeout(persist, timeout);
  };
};

export default createPersistor;
